/*
* @Author: abhishek714
* @Date:   2024-04-17 16:07:15
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 19:03:26
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

const parentHost = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

class UserServices {
	async register(data){
		if (data.refer_other && data.refer_other.trim() !== "") {
		  data.refer = data.refer_other;
		  delete data.refer_other;
		}

		const url = `${API_URL}register`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async login(data){
		const url = `${API_URL}login`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async updateProfile(data, auth_token){
		const url = `${API_URL}update-profile`;
		const headers = {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer '+auth_token+'',
		};
		//let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: data,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async changePassword(data, auth_token){
		const url = `${API_URL}change-password`;
		const headers = {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer '+auth_token+'',
		};
		//let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: data,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async forgotPassword(data){
		const url = `${API_URL}forgot-password`;
		const headers = {
			'Content-Type': 'multipart/form-data',
		};
		//let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: data,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async getPaymentMethod(auth_token){
		const url = `${API_URL}get-payment-method`;
		const headers = {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'GET',
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async getBookingHistory(data,auth_token){
		const url = `${API_URL}get-booking-history`;
		const headers = {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer '+auth_token+'',
		};
		console.log(data);
		data.site_url = parentHost;
		//console.log(data);
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async getOrder(data, auth_token){
		const url = `${API_URL}get-order`;
		const headers = {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer '+auth_token+'',
		};
		data.site_url = parentHost;
		console.log(data);
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}

	async getPaymentHistory(auth_token){
		const url = `${API_URL}get-payment-history`;
		const headers = {
			//'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'GET',
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			console.log(error);
			return error.response;
		});
	}
}
export default UserServices;
/*
* @Author: abhi
* @Date:   2024-04-26 18:05:41
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:18:37
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

class MembershipPlanServices {
	async getMembershipPlans(data){
		const url = `${API_URL}get-membership-plans`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async getMembershipPlanDetails(data){
		const url = `${API_URL}get-membership-plan-details`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

}
export default MembershipPlanServices;
/*
* @Author: abhishek714
* @Date:   2024-07-01 11:26:03
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 17:32:32
*/
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL,redirectMessages, clearData } from '../../settings/common';
import { setCurrentUrl, useGetRedirectUrl } from '../../helpers/iframe-url-listner';
import { checkClient } from '../../helpers/check-client';
import CommonServices from '../../services/CommonServices';
import { showSuccessMessage, showErrorMessage, showWarnMessage } from '../../helpers/flash-messages';
import { AppContext } from '../../components/elements/AppContext';

const IndexPage = () => {
	const { operation_status, operation_type } = useParams();
	const [loading, setLoading] = useState(true);
	const getRedirectUrl = useGetRedirectUrl();
	const navigate = useNavigate();
	const [isAuthorized, setIsAuthorized] = useState(false);
	const { setSharedActiveMenu } = useContext(AppContext);

	useEffect(() => {
		const commonServices = new CommonServices();
		checkClient().then(response => {
			if(response) {
				const redirectUrl = getRedirectUrl();
				if (redirectUrl) {
					sessionStorage.setItem('active_menu',`${redirectUrl}`);
					setSharedActiveMenu(`${redirectUrl}`);
					navigate(`${INDEX_URL}${redirectUrl}`);
				} else {
					setCurrentUrl('classes');
					sessionStorage.setItem('active_menu','classes');
					setSharedActiveMenu(`classes`);
					navigate(`${INDEX_URL}classes`);
				}
				setIsAuthorized(true);
			} else {
				setLoading(false);
			}
		});
	},[]);

	useEffect(() => {
		let message = redirectMessages(operation_status, operation_type);
		if(message !== '') {
			clearData();
			if(operation_status === 'success') {
				showSuccessMessage(`${message}`);
			} else if(operation_status === 'pending') {
				showWarnMessage(`${message}`);
			} else {
				showErrorMessage(`${message}`);
			}
		}
		sessionStorage.removeItem('cart_data');
	},[operation_status,operation_type]);

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0"></h1>
								</div>
							</div>
						</div>
					</div>
				</section>
		        <section className="classes-section common-padding">
		        	<div className="container">
		        		<div className="row">
		        			<div className="col-md-12">	
		        				{loading && (
					                <div className="row">
					                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
					                        <div className="spinner"></div>
					                    </div>
					                </div>
					            )}
					            {!loading && !isAuthorized && (
					            	<div className="row text-center">
					            		<h1>Not Authorised.</h1>
					            	</div>
					            )}
					        </div>
		        		</div>
		        	</div>
		        </section>
		    </main>
		</React.Fragment>
	);
};

export default IndexPage;
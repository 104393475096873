import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { INDEX_URL } from '../../settings/common';
import { AppContext } from './AppContext';
import { cartItemCounts } from '../../helpers/cart';

const Header = () => {
  const [isLogin, setIsLogin] = useState(0);
  const [authUser, setAuthUser] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const { SharedCartCount, SharedAuthUser, SharedActiveMenu } = useContext(AppContext);

  useEffect(() => {
    const active_menu = sessionStorage.getItem('active_menu');
    if (typeof active_menu !== 'undefined' && active_menu !== null) {
      setActiveMenu(active_menu);
    }
  }, []);

  useEffect(() => {
    setCartCount(SharedCartCount);
  }, [SharedCartCount]);

  useEffect(() => {
    setCartCount(cartItemCounts);
  }, [cartItemCounts]);

  useEffect(() => {
    setActiveMenu(SharedActiveMenu);
  }, [SharedActiveMenu]);

  useEffect(() => {
    const user = SharedAuthUser;
    const auth_user = localStorage.getItem('auth_user');
    if (Object.keys(user).length > 0) {
      setAuthUser(user);
      setIsLogin(1);
    } else if (auth_user) {
      setAuthUser(JSON.parse(auth_user));
      setIsLogin(1);
    } else {
      setAuthUser({});
      setIsLogin(0);
    }
  }, [SharedAuthUser]);

  const handleClickEvent = (link) => {
    sessionStorage.setItem('active_menu', link);
    setActiveMenu(link);
  };

  return (
    <React.Fragment>
      <header className="headerSection trs">
        <div className="container">
          <nav className="navbar navbar-expand-md">
            <Link className="navbar-brand" to={INDEX_URL + 'classes'} onClick={() => handleClickEvent('classes')}>
              <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarsExample04">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item"><Link className={activeMenu === 'classes' ? "nav-link active" : "nav-link"} to={INDEX_URL + 'classes'} onClick={() => handleClickEvent('classes')}>Classes</Link></li>
                <li className="nav-item"><Link className={activeMenu === 'appointments' ? "nav-link active" : "nav-link"} to={INDEX_URL + 'appointments'} onClick={() => handleClickEvent('appointments')}>Appointments</Link></li>
                <li className="nav-item"><Link className={activeMenu === 'pricing' ? "nav-link active" : "nav-link"} to={INDEX_URL + 'pricing'} onClick={() => handleClickEvent('pricing')}>Pricing</Link></li>
                <li className="nav-item"><Link className={activeMenu === 'shop' ? "nav-link active" : "nav-link"} to={INDEX_URL + 'shop'} onClick={() => handleClickEvent('shop')}>Shop</Link></li>
                {isLogin ? (
                  <li className="nav-item"><Link className="nav-link" to={INDEX_URL + 'profile'} onClick={() => handleClickEvent('profile')}>{authUser.display_name !== '' ? authUser.display_name : authUser.first_name + ' ' + authUser.last_name}</Link></li>
                ) : (
                  <li className="nav-item"><Link className="nav-link" to={INDEX_URL + 'login'} onClick={() => handleClickEvent('login')}>Login / Register</Link></li>
                )}
                <li className="nav-item">
                  <Link className={activeMenu === 'cart' ? "nav-link active" : "nav-link"} to={INDEX_URL + 'cart'} onClick={() => handleClickEvent('cart')}>
                    <button type="button" className="btn btn-cart position-relative">
                      Cart {cartCount > 0 ? (
                        <React.Fragment>
                          <i className="fa-solid fa-cart-shopping fa-fw"></i>
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {cartCount}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
/*
* @Author: abhi
* @Date:   2024-04-26 13:01:24
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:31:16
*/
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const AddToCart = (item) => {
  let cart_data = sessionStorage.getItem('cart_data');

  const addToCart = (item) => {
    if (cart_data === null) {
      cart_data = {
        items: [{
          'quantity': item.quantity,
          'product_size_id': item.product_size_id,
          'product_id': item.product_id,
          'product_amount': item.product_amount,
          'product_image': item.product_image,
          'product_name': item.product_name,
          'remaining_quantity': item.remaining_quantity
        }],
        price: item.quantity * item.product_amount // Initialize price with the first item's total price
      };
    } else {
      cart_data = JSON.parse(cart_data);
      const existingProductIndex = cart_data.items.findIndex(product => product.product_id === item.product_id);
      if (existingProductIndex !== -1) {
        // If product already exists, update its quantity and price
        cart_data.items[existingProductIndex].quantity = item.quantity;
        //cart_data.price += item.quantity * item.product_amount;
      } else {
        // If product doesn't exist, add it to the cart
        cart_data.items.push({
          product_id: item.product_id,
          quantity: item.quantity,
          product_size_id: item.product_size_id,
          product_amount: item.product_amount,
          product_image: item.product_image,
          product_name: item.product_name,
          remaining_quantity: item.remaining_quantity
        });
      }
    }
    cart_data.price = cartTotalAmount(cart_data);
    sessionStorage.setItem('cart_data', JSON.stringify(cart_data));
  }

  if (typeof cart_data !== 'undefined' && cart_data !== null) {
    addToCart(item);
  } else {
    addToCart(item);
  }
  return cart_data;
}

const cartTotalAmount = (cart_data) => {
	if(typeof cart_data !== 'undefined' && cart_data !== null) {
		let price = 0;
		cart_data.items.map((value, index) => {
			price = price + (value.quantity * value.product_amount);
		});
		return price = parseFloat(price).toFixed(2);
  }
}




export const CartData = (product_id=null) => {
	let cart_data = sessionStorage.getItem('cart_data');
	if(typeof cart_data !== 'undefined' && cart_data !=='' && cart_data !== null) {
		cart_data = JSON.parse(cart_data);
		if(product_id !== null) {
			const filteredProducts = cart_data.items.filter(product => product.product_id === product_id);
			if (filteredProducts.length === 1) {
				return filteredProducts[0]; // Return the single object if it exists
			} else {
				return filteredProducts; // Otherwise, return the array
			}
		}
	}
	return cart_data;
}

export const cartItemCounts = () => {
  let items = 0;
  let cart_data = sessionStorage.getItem('cart_data');
  if(typeof cart_data !== 'undefined' && cart_data !=='' && cart_data !== null) {
    cart_data = JSON.parse(cart_data);
    items = Object.keys(cart_data.items).length;
  }
  return items
}

export const removeItem = (product_id) => {
  let cart_data = sessionStorage.getItem('cart_data');
  const updated_cart_data = [];
  if(typeof cart_data !== 'undefined' && cart_data !=='' && cart_data !== null) {
    cart_data = JSON.parse(cart_data);
    sessionStorage.removeItem('cart_data');
    Object.entries(cart_data.items).map(([key, value])=> {
      if(value.product_id !== product_id) {
        AddToCart(value);
      }
    })
  }

}
/*
* @Author: abhishek714
* @Date:   2024-04-10 14:32:35
* @Last Modified by:   abhi
* @Last Modified time: 2024-04-27 14:25:10
*/
import React from "react";

const Footer = () => {
	return(
		<React.Fragment>
			<footer>	
				<div className="copyRight">
					<div className="container">		
						<div className="row">
							<div className="col-md-8 align-self-center">
								<p>Copyright &copy; <strong>Bookee</strong> 2024. All rights reserved.</p>
							</div>
							<div className="col-md-4">
								<p className="rightTxt"><a href=""><i className="fa-brands fa-facebook-f fa-fw"></i></a>&nbsp;&nbsp;<a href=""><i className="fa-brands fa-instagram fa-fw"></i></a>&nbsp;&nbsp;<a href=""><i className="fa-brands fa-twitter fa-fw"></i></a></p>
							</div>
						</div>
					</div>	
				</div>	
			</footer>
		</React.Fragment>
	)
}

export default Footer;
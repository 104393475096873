/*
* @Author: abhishek714
* @Date:   2024-04-10 15:56:02
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:19:08
*/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SITE_TITLE, INDEX_URL } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import UserSidebar from '../../components/elements/UserSidebar';
import { Validation } from '../../helpers/validation';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const ChangePasswordPage = () => {
	const [authUser, setAuthUser] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	
	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
		}
		seo({
	      title: SITE_TITLE+' || Change Password',
	      metaDescription: '',
	    });
	    setCurrentUrl('change-password');
	    sessionStorage.setItem('active_menu','change-password');
	}, []);

	const handleChange = (e) => {
	    const { name, value } = e.target;
	    const newFormData = {
	        ...formData,
	        [name]: value,
	    };
	    setFormData(newFormData);
	};


	const handleSubmit = (e) => {
  		e.preventDefault();
	  	const errors = Validation(formData,['old_password','password','confirm_password']);
	  	if (Object.keys(errors).length > 0) {
	      setErrors(errors);
	      return;
	    }
	    submitForm();
  	}

  	const submitForm = async () => {
  		setErrors({});
  		setLoading(true);
  		const userServices = new UserServices();
  		userServices.changePassword(formData, authUser.auth_token).then(response => {
  			setFormData({});
  			let data = response.data;
	  		if(data.status === 'success') {
	  			showSuccessMessage(data.message);
	  			navigate(INDEX_URL+'profile');
	  		} else {
	  			showErrorMessage(data.message);
	  		}
  			setLoading(false);
  		});
  	}

	const renderForm = () => {
		return(
			<React.Fragment>
				<div className="">
					<form id="change-password-form" method="post" onSubmit={handleSubmit}>
						{errors.apiError && <p className="text-danger">{errors.apiError}</p>}
						<div className="row">
							<h5>Change Password</h5>
							<div className="col-md-12">
								<div className="mb-3">
									<input type="password" className="form-control" placeholder="Current Password" id="old_password" name="old_password" value={formData.old_password || null } onChange={handleChange}/>
									{errors.old_password && <div className="text-danger">{errors.old_password}</div>}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<input type="password" className="form-control" placeholder="New Password" id="password" name="password" value={formData.password || null } onChange={handleChange}/>
									{errors.password && <div className="text-danger">{errors.password}</div>}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<input type="password" className="form-control" placeholder="Confirm Password" id="confirm_password" name="confirm_password" value={formData.confirm_password || null } onChange={handleChange}/>
									{errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<div className="mb-3">
								<button type="submit" className="btn btn-primary" disabled={loading}>Save</button>
							</div>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
	return (
		<React.Fragment>
			{loading && (
		        <div className="overlay">
		          <div className="loading">
		            <div className="spinner"></div>
		          </div>
		        </div>
		    )}
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Change Password</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="profile-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								{renderForm()}
							</div>
						</div>
					</div>
				</section>
			</main>  
		</React.Fragment>
	);
}

export default ChangePasswordPage;
/*
* @Author: abhi
* @Date:   2024-04-18 13:12:11
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-15 11:15:12
*/
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonServices from '../services/CommonServices';

export const checkClient = async () => { // Make checkClient an async function
    return new Promise((resolve, reject) => { // Return a Promise
        const getClientData = async (parentHost) => {
            const commonServices = new CommonServices();
            if (typeof parentHost !== 'undefined' && parentHost !== '' && parentHost !== null) {
                try {
                    const response = await commonServices.checkClient(parentHost);
                    const data = response.data;
                    if (data.status === 'success') {
                        if (data.data.is_active) {
                            if (Object.keys(data.data.location).length > 0) {
                                data.data.location = data.data.location[0];
                                localStorage.setItem('company_data', JSON.stringify(data.data));
                                resolve(true); // Resolve the Promise with true
                            } else {
                                resolve(false); // Resolve the Promise with false
                            }
                        } else {
                            resolve(false); // Resolve the Promise with false
                        }
                    } else {
                        resolve(false); // Resolve the Promise with false
                    }
                } catch (error) {
                    reject(error); // Reject the Promise with the error
                }
            } else {
                resolve(false); // Resolve the Promise with false
            }
        };

        let company_data = localStorage.getItem('company_data');
        const parentHost = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

        if (typeof parentHost !== 'undefined' && parentHost !== '' && parentHost !== null) {
            if (typeof company_data !== 'undefined' && company_data !== '' && company_data !== null) {
                company_data = JSON.parse(company_data);
                if (parentHost === company_data.site_url) {
                    resolve(true); // Resolve the Promise with true
                } else {
                    getClientData(parentHost)
                        .then(result => resolve(result)) // Resolve the Promise with the result from getClientData
                        .catch(error => reject(error)); // Reject the Promise with the error from getClientData
                }
            } else {
                getClientData(parentHost)
                    .then(result => resolve(result)) // Resolve the Promise with the result from getClientData
                    .catch(error => reject(error)); // Reject the Promise with the error from getClientData
            }
        } else {
            resolve(false); // Resolve the Promise with false
        }
    });
};


/*
* @Author: abhishek714
* @Date:   2024-04-12 12:37:22
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:18:29
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

class CommonServices {
	async checkClient(site_url){
		const url = `${API_URL}check-client`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = { site_url : site_url};
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async getReferal(){
		const url = `${API_URL}get-referal`;
		const headers = {
			'Content-Type': 'application/json'
		};
		return axios({
			url:url,
			method: 'GET',
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async checkCouponCode(data, auth_token){
		const url = `${API_URL}check-coupon-code`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'POST',
			data: data,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async searchFilter(data){
		const url = `${API_URL}search-filter`;
		const headers = {
			'Content-Type': 'application/json'
		};
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}



}

export default CommonServices;
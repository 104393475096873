/*
* @Author: abhi
* @Date:   2024-04-20 19:56:55
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:17:13
*/
import { MAIL_FORMAT, ucwords } from '../settings/common';
export const Validation = (data, validate_fields) => {
	let errors = {};
	if(typeof validate_fields !== 'undefined' && validate_fields.length > 0) {
		validate_fields.map((value) => {
			if(value == 'email') {
				if(!data[value]) {
					errors[value] = ucwords(value.replace("_", " "))+' is required.';
				} else {
					if(!MAIL_FORMAT.test(data[value])) {
						errors[value] = ucwords(value.replace("_", " "))+' is invalid.';
					}
				}
			} else if(value == 'confirm_password') {
				if (!data[value]) {
					errors[value] = ucwords(value.replace("_", " "))+' is required.';
				}
				if (data[value] !== data['password']) {
					errors[value] = 'Please enter the same value again.';
				}
			} else if(value == 'child_id') {
				if(!data[value]) {
					errors[value] = 'Child is required.';
				}
			} else if(value == 'membership_plan_id') {
				if(!data[value]) {
					errors[value] = 'Membership plan is required.';
				}
			} else if(!data[value]) {
				errors[value] = ucwords(value.replace(/_/g, " "))+' is required.';
			}
		});
	}
	return errors;
}
/*
* @Author: abhishek714
* @Date:   2024-04-29 13:00:19
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:18:39
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

class OrderServices {
	async addOrder(data, auth_token){
		const url = `${API_URL}add-order`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'POST',
			data: data,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}
}
export default OrderServices;
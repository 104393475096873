/*
* @Author: abhi
* @Date:   2024-06-11 20:06:12
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:17:36
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SITE_TITLE, PAYMENT_GATEWAY_URL, INDEX_URL, ucwords } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import ChildServices from '../../services/ChildServices';
import UserSidebar from '../../components/elements/UserSidebar';
import moment from 'moment';

const PaymentPage = () => {
	const [authUser, setAuthUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [payments, setPayments] = useState({});
	const [companyData, setCompanyData] = useState({});
	const userServices = new UserServices();
	const childServices = new ChildServices();
	
	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
			//getPaymentHistory(JSON.parse(auth_user).auth_token);
		}
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
			company_data = JSON.parse(company_data);
  			setCompanyData(company_data);
  		}
		seo({
	      title: SITE_TITLE+' || Payments',
	      metaDescription: '',
	    });
		setCurrentUrl(`payments`);
		sessionStorage.setItem('active_menu','payments');

	},[]);
	
	useEffect(() => {
		getPaymentHistory(authUser.auth_token);
	},[authUser,companyData])

	const getPaymentHistory = (auth_token =null) => {
		if(auth_token !== null) {
			userServices.getPaymentHistory(auth_token).then(response => {
				setLoading(false);
				if(response.data.status === 'success') {
					setPayments(response.data.data);
				} else {
					if(response.data.error === 'authentication_error') {
						localStorage.removeItem("auth_user");
						sessionStorage.removeItem("current_url");
						window.location.href = INDEX_URL;
					}
				}
			});
		}
	}

	const renderPaymentHistory = () => {
		return (
			<React.Fragment>
				<div className="row">
            		<div className="col-md-9">
            			<h5>Payment History</h5>
            		</div>
            	</div>
            	{loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
            	{!loading && Object.keys(payments).length > 0 ? (
				    <React.Fragment>
				    	<div className="table-container">
				    		<table className="table-scroll">
				                <thead>
				                  <tr>
				                    <th>Date & Time</th>
				                    <th>Amount</th>
				                    <th>Payment Mode</th>
				                    <th>Status</th>
				                  </tr>
				                </thead>
				                <tbody>
				                	{Object.entries(payments).map(([key, value]) => (
				                	value.payment !== undefined && value.payment !== null ? (
				                		<tr key={key}>
				                			<td>
				                				<p className="m-0">{moment(value.payment.updated_at).format('h:mm A, D MMM YYYY')}</p>
				                				<p>{value.payment.transaction_id}</p>
				                			</td>
				                			<td>{companyData.location.currency_symbol}{value.total_amount}</td>
				                			<td>{(value.payment.payment_mode === 'cash') ? 'Cash' : 'Saved card'}</td>
				                			<td><span className={(value.payment.status === 'completed') ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'}>{value.payment.status}</span></td>
				                		</tr>
				                	) : null
				                	))}
				                </tbody>
				            </table>
			            </div>
				    </React.Fragment>
				) : null}
	            {!loading && Object.keys(payments).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <p className="text-center">
	                            <h3>No upcoming booking found.</h3>
	                        </p>
	                    </div>
	                </div>
	            ) : null}
	        </React.Fragment>
		)
	}
	
	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Payments</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="memberships-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								<div className="table-responsive mt-3" id="memberships-table">
					            	{renderPaymentHistory()}
					            </div>
							</div>
						</div>
					</div>
				</section>
			</main>  
		</React.Fragment>
	)

}

export default PaymentPage
/*
* @Author: abhishek714
* @Date:   2024-04-10 15:56:02
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:35:23
*/
import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SITE_TITLE, GENDERS, COUNTRY_LIST, INDEX_URL, VALID_IMAGE_TYPES, ucwords } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import UserSidebar from '../../components/elements/UserSidebar';
import { AppContext } from '../../components/elements/AppContext';
import { showSuccessMessage } from '../../helpers/flash-messages';

const ProfilePage = () => {
	const [isLogin, setIsLogin] = useState(0);
	const [showEdit, setShowEdit] = useState(false);
	const [authUser, setAuthUser] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const fileInputRef = useRef(null);
	const { setSharedAuthUser } = useContext(AppContext);
	
	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
			setIsLogin(1);
		}
		seo({
	      title: (isLogin ? (authUser.display_name !=='') ? SITE_TITLE + ' || '+authUser.display_name : SITE_TITLE + ' || '+authUser.first_name+' '+authUser.last_name : SITE_TITLE + ' || Profile'),
	      metaDescription: '',
	    });
	    setCurrentUrl('profile');
	    sessionStorage.setItem('active_menu','profile');

	}, [authUser.display_name,authUser.first_name,authUser.last_name,isLogin]);

	const showProfile = (e) => {
		e.preventDefault();
		setShowEdit(!showEdit);
		setFormData({});
		setImagePreview(null);
	}

	const handleAddImageClick = (e) => {
		e.preventDefault();
		if (fileInputRef.current && showEdit) {
			fileInputRef.current.click();
		}
	}

	const handleChange = (e) => {
	    const { name, value, files } = e.target;
	    if (name === 'profile_image') {
	      handleProfileImageChange(name,files);
	    } else {
	      const newFormData = {
	        ...formData,
	        [name]: value,
	      };
	      setFormData(newFormData);
	    }
	};

	const handleProfileImageChange = (name,files) => {
		if (files && files[0]) {
			const file = files[0];
			if (!VALID_IMAGE_TYPES.includes(file.type)) {
				setErrors({profile_image:'Invalid image type. Please upload a JPEG, PNG, or GIF file.'});
				return;
			} else {
				setErrors({profile_image:null});
			}
			const reader = new FileReader();
			reader.onload = (e) => {
				setImagePreview(e.target.result);
			};
			reader.readAsDataURL(file);
			const newFormData = {
				...formData,
				[name]: file,
			};
			setFormData(newFormData);
		}
	};

	const handleSubmit = (e) => {
  		e.preventDefault();
  		const error = {};
  		if (Object.keys(error).length > 0 || (typeof errors.profile_image !== 'undefined' && errors.profile_image !== null)) {
	  		if(typeof errors.profile_image !== 'undefined'  && errors.profile_image !== null) {
	  			error['profile_image'] = 'Invalid image type. Please upload a JPEG, PNG, or GIF file.';
	  		}
	  		setErrors(error);
	  		return;
	  	}
	    submitForm();
  	}

  	const submitForm = async () => {
  		setLoading(true);
  		const userServices = new UserServices();
  		if(typeof formData.date_of_birth !== 'undefined' && formData.date_of_birth !== null && formData.date_of_birth !== '') {
  			formData.date_of_birth = moment(formData.date_of_birth).format('YYYY-MM-DD');
  		}
  		userServices.updateProfile(formData, authUser.auth_token).then(response => {
  			let data = response.data;
	  		if(data.status === 'success') {
	  			localStorage.setItem('auth_user', JSON.stringify(data.data));
	  			setSharedAuthUser(data.data);
	  			setAuthUser(data.data);
	  			localStorage.setItem('auth_user', JSON.stringify(data.data));
	  			setShowEdit(false);
	  			showSuccessMessage('Profile updated successfully.');
	  		} else {
				if(response.data.error === 'authentication_error') {
					localStorage.removeItem("auth_user");
					sessionStorage.removeItem("current_url");
					window.location.href = INDEX_URL;
				}
			}
  			setLoading(false);
  		});
  	}

	const renderProfileImage = () => {
		return(
			<React.Fragment>
				<div className="profile-img">
					<img src={imagePreview || authUser.profile_image} alt="Preview" className="rounded-circle d-block mx-auto" style={{ width: '200px', height: '200px' }}/>
					<input ref={fileInputRef} type="file" accept="image/*" id="profile_image" name="profile_image" onChange={handleChange} style={{ display: 'none' }} />
					{(showEdit) ? 
						<React.Fragment>
							<label htmlFor="profile_image">
							<i className="fa-solid fa-plus fa-fw" onClick={handleAddImageClick} style={{ cursor : 'pointer' }}></i>
							</label>
							{errors.profile_image && <div className="text-danger">{errors.profile_image}</div>}
						</React.Fragment>
						: null
					}
				</div>
			</React.Fragment>
		)
	}

	const genders = () => {
	    const gender_list = GENDERS;
	    return (
	        <React.Fragment>
	            <select className="form-select" id="gender" name="gender" value={formData.gender || authUser.gender} onChange={handleChange}>
	                <option value="">-----Select Gender-----</option>
	                {Object.entries(gender_list).map(([key, value]) => (
	                    <React.Fragment key={key}>
	                        <option value={key}>{value}</option>
	                    </React.Fragment>
	                ))}
	            </select>
	        </React.Fragment>
	    );
	};

	const CountrySelect = () => {
	    const country_list = COUNTRY_LIST;
	    return (
	    	<select className="form-select" id="country" name="country" value={formData.country || authUser.country} onChange={handleChange}>
	            {Object.entries(country_list).map(([key, value]) => (
	                <React.Fragment key={key}>
                        <option value={key}>{value}</option>
                    </React.Fragment>
	            ))}
	        </select>
	    );
	};
	
	const renderProfile = () => {
		return(
			<React.Fragment>
				<form id="edit-profile-form" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
					<div className="row">
						<div className="col-md-12">
							<div className="row">
							<div className="col-md-4">
								{renderProfileImage()}
							</div>
							<div className="col-md-8">	
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="first-name">First Name:</label>
										</div>
										<div className="col-md-7">
											{(showEdit) ? 		
											<input type="text" className="form-control" placeholder="First Name" id="first_name" name="first_name" value={formData.first_name !== undefined ? formData.first_name : authUser.first_name} onChange={handleChange}/>
											:
												authUser.first_name
											}
											{errors.first_name && <div className="text-danger">{errors.first_name}</div>}
										</div>	
									</div>
								</div>	
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="last_name">Last Name:</label>
										</div>
										<div className="col-md-7">
											{(showEdit) ? 		
											<input type="text" className="form-control" placeholder="Last Name" id="last_name" name="last_name" value={formData.last_name !== undefined ? formData.last_name : authUser.last_name} onChange={handleChange}/>
											:
												authUser.last_name
											}
											{errors.last_name && <div className="text-danger">{errors.last_name}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="display_name">Display Name:</label>
										</div>
										<div className="col-md-7">
											{(showEdit) ? 		
											<input type="text" className="form-control" placeholder="Display Name" id="display_name" name="display_name" value={formData.display_name !== undefined ? formData.display_name : authUser.display_name} onChange={handleChange}/>
											:
												authUser.display_name
											}
											{errors.display_name && <div className="text-danger">{errors.display_name}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="phone-number">Phone Number:</label>
										</div>
										<div className="col-md-7">
											{(showEdit) ? 		
											<input type="text" className="form-control" placeholder="Phone number" id="phone_number" name="phone_number" value={formData.phone_number !== undefined ? formData.phone_number : authUser.phone_number} onChange={handleChange}/>
											:
												authUser.phone_number
											}
											{errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="gender">Gender:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ? 
												<React.Fragment>
													{genders()}
												</React.Fragment>
												:
												ucwords(authUser.gender)
											}
											{errors.gender && <div className="text-danger">{errors.gender}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="date-of-birth">Date of Birth:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ? 
												<DatePicker className="form-control datepicker" id="date_of_birth" name="date_of_birth" placeholderText="DD/MM/YYYY" selected={formData.date_of_birth  ? new Date(formData.date_of_birth) : authUser.date_of_birth} onChange={date => handleChange({ target: { name: 'date_of_birth', value: date } })} dateFormat="dd/MM/yyyy"/>
												:
												(authUser.date_of_birth !== 'undefined' && authUser.date_of_birth !== null && authUser.date_of_birth !== '') ? moment(authUser.date_of_birth).format('DD MMMM YYYY') : null
											}
											{errors.date_of_birth && <div className="text-danger">{errors.date_of_birth}</div>}

										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="address">House no/ Street/ Locality:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ?
												<input type="text" className="form-control" placeholder="House no/ Street/ Locality" id="address" name="address" value={formData.address !== undefined ? formData.address : authUser.address} onChange={handleChange}/>
												:
												authUser.address
											}
											{errors.address && <div className="text-danger">{errors.address}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">											
											<label htmlFor="city">City:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ?
												<input type="text" className="form-control" placeholder="City" id="city" name="city" value={formData.city !== undefined ? formData.city : authUser.city} onChange={handleChange}/>
												:
												ucwords(authUser.city)
											}
											{errors.city && <div className="text-danger">{errors.city}</div>}
										</div>		
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">											
											<label htmlFor="state">State:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ?
												<input type="text" className="form-control" placeholder="State" id="state" name="state" value={formData.state !== undefined ? formData.state : authUser.state} onChange={handleChange}/>
												:
												ucwords(authUser.state)
											}
											{errors.state && <div className="text-danger">{errors.state}</div>}
										</div>		
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">											
											<label htmlFor="country">Country:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ?
												<React.Fragment>
													{CountrySelect()}
												</React.Fragment>
												:
												COUNTRY_LIST[authUser.country]
											}
											{errors.country && <div className="text-danger">{errors.country}</div>}
										</div>		
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">											
											<label htmlFor="zip_code">Zip code:</label>
										</div>
										<div className="col-md-7">		
											{(showEdit) ?
												<input type="text" className="form-control" placeholder="Zip code" id="zip_code" name="zip_code" value={formData.zip_code !== undefined ? formData.zip_code : authUser.zip_code} onChange={handleChange}/>
												:
												authUser.zip_code
											}
											{errors.zip_code && <div className="text-danger">{errors.zip_code}</div>}
										</div>		
									</div>
								</div>
								<div className="col-md-12">
									<div className="mb-3">
										{(showEdit) ? 
											<div className="btn-right">
												<button type="button" className="btn cancel-btn" onClick={showProfile}>Cancel</button> <button type="submit" className="btn save-btn" disabled={loading}>Save</button>
											</div>
											:
											<div className="">
												<button type="button" className="btn save-btn" onClick={showProfile}>Edit Profile</button>
											</div>
										}
									</div>	
								</div>	
							</div>		
						</div>	
						</div>
					</div>
				</form>
			</React.Fragment>
		)
	}
	return (
		<React.Fragment>
			{loading && (
		        <div className="overlay">
		          <div className="loading">
		            <div className="spinner"></div>
		          </div>
		        </div>
		    )}
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Profile</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="profile-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								{renderProfile()}
							</div>
						</div>
					</div>
				</section>
			</main>  
		</React.Fragment>
	);
}

export default ProfilePage;
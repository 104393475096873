/*
* @Author: abhi
* @Date:   2024-06-11 20:06:12
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 19:06:51
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SITE_TITLE, PAYMENT_GATEWAY_URL, INDEX_URL, ucwords } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import ChildServices from '../../services/ChildServices';
import UserSidebar from '../../components/elements/UserSidebar';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const MembershipPage = () => {
	const [authUser, setAuthUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [orderData, setOrderData] = useState({});
	const [activePlanData, setActivePlanData] = useState({});
	const [expiredPlanData, setExpiredPlanData] = useState({});
	const [children, setChildren] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [selectedChild, setSelectedChild] = useState(null);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [requestData, setRequestData] = useState({});

	const userServices = new UserServices();
	const childServices = new ChildServices();

	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if (typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
			getOrder(JSON.parse(auth_user));
			getChildren(JSON.parse(auth_user));
		}
		seo({
			title: SITE_TITLE + ' || Memberships and packs',
			metaDescription: '',
		});
		setCurrentUrl(`memberships`);
		sessionStorage.setItem('active_menu', 'memberships');
	}, []);

	useEffect(() => {
		createData();
	}, [orderData]);

	const createData = () => {
		var activePlans = {};
		var expiredPlans = {};
		if (Object.keys(orderData).length > 0) {
			Object.entries(orderData).map(([key, value]) => {
				if (value.order_type === 'subscription' || value.order_type === 'membership')
					if (value.membership_plan_status === 'active' && value.customer_membership_plan !== null) {
						activePlans[key] = value;
					} else if (value.membership_plan_status === 'expired' && value.customer_membership_plan !== null) {
						expiredPlans[key] = value;
					}
			})
		}
		setActivePlanData(activePlans);
		setExpiredPlanData(expiredPlans);
	}

	const getOrder = (auth) => {
		let data = {location_id: auth.location_id, company_id: auth.company_id };
		console.log(data);
		userServices.getOrder(data,auth.auth_token).then(response => {
			setLoading(false);
			if (response.data.status === 'success') {
				setOrderData(response.data.data);
			} else {
				if (response.data.error === 'authentication_error') {
					localStorage.removeItem("auth_user");
					sessionStorage.removeItem("current_url");
					showErrorMessage(`${response.data.message}`)
					window.location.href = INDEX_URL;
				}
			}
		});
	}

	const getChildren = (auth) => {
		let data = {company_id:auth.company_id, location_id:auth.location_id};
		childServices.getChildren(auth.auth_token, data).then(response => {
			if (response.data.status === 'success') {
				setChildren(response.data.data);
			}
		});
	}

	const addChild = (child_id, customer_membership_plan_id) => {
		if(child_id) {
			setSelectedChild(child_id);
			setSelectedPlan(customer_membership_plan_id);
			setShowModal(true);
		} else {
			setSelectedChild(null);
			setSelectedPlan(null);
		}
	}

	const handleModalClose = () => {
		setShowModal(false);
		setSelectedChild(null);
		setSelectedPlan(null);
	}

	const handleConfirm = () => {
		let formData = {child_id: selectedChild, customer_membership_plan_id: selectedPlan}
		childServices.assignChild(authUser.auth_token,formData).then(response => {
			if(response.data.status === 'success') {
				getOrder(authUser);
				getChildren(authUser);
				showSuccessMessage(`${response.data.message}`);
			} else {
				showErrorMessage(`${response.data.message}`);
			}
		});
		setShowModal(false);
	}

	const renderActivePlans = () => {
		return (
			<React.Fragment>
				<div className="row">
					<div className="col-md-9">
						<h5>Active & Upcoming</h5>
					</div>
				</div>
				{loading && (
					<div className="row">
						<div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
							<div className="spinner"></div>
						</div>
					</div>
				)}
				{!loading && Object.keys(activePlanData).length > 0 ? (
					<React.Fragment>
						<div className="table-container">
							<table className="table-scroll">
								<thead>
									<tr>
										<th>Plan</th>
										<th>Credits</th>
										<th>Expiring on</th>
										<th>Shared with</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(activePlanData).map(([key, value]) => (
										<tr key={key}>
											<td><p className="m-0"><b>{(value.customer_membership_plan !== null && typeof value.customer_membership_plan !== 'undefined' && typeof value.customer_membership_plan.membership_plan !== 'undefined' && value.customer_membership_plan.membership_plan !== null) ? ucwords(value.customer_membership_plan.membership_plan.name) : ''}</b>{(value.customer_membership_plan.child !== null) && <i className="fa-solid fa-user-group fa-fw"></i>}<br /> <a href="" className="active-btn">Active</a></p></td>
											<td><p className="m-0">{(value.customer_membership_plan !== null && value.customer_membership_plan.all_credits !== null) ? `${value.customer_membership_plan.remaining_credits}/${value.customer_membership_plan.all_credits}` : (value.customer_membership_plan !== null && value.customer_membership_plan.all_credits === null) ? 'Unlimited credits' : ''} </p></td>
											<td><p className="m-0">{(value.customer_membership_plan !== null) ? moment(value.customer_membership_plan.end_date).format('D MMM YYYY') : ''}</p></td>
											<td>
												{
													value.customer_membership_plan.child === null ? (
														<React.Fragment>
															<p className="m-0">Sharing members 1/1</p>
															<select className="form-select" onChange={(e) => addChild(e.target.value, value.customer_membership_plan.id)} value={selectedChild || ''}>
																<option value="" selected>----Select child----</option>
																{Object.entries(children).map(([key, child]) => (
																	<option key={key} value={child.id}>{child.first_name} {child.last_name}</option>
																))}
															</select>
														</React.Fragment>
													) : (
														<p className="m-0">{value.customer_membership_plan.child.first_name} {value.customer_membership_plan.child.last_name}</p>
													)
												}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</React.Fragment>
				) : null}
				{!loading && Object.keys(activePlanData).length === 0 ? (
					<div className="row">
						<div className="col-md-12">
							<h3 className="text-center">No active & upcoming plans found.</h3>
						</div>
					</div>
				) : null}
			</React.Fragment>
		)
	}

	const renderExpiredPlan = () => {
		return (
			<React.Fragment>
				<div className="row">
					<div className="col-md-9">
						<h5>Expired</h5>
					</div>
				</div>
				{loading && (
					<div className="row">
						<div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
							<div className="spinner"></div>
						</div>
					</div>
				)}
				{!loading && Object.keys(expiredPlanData).length > 0 ? (
					<React.Fragment>
						<div className="table-container">
							<table className="table-scroll">
								<thead>
									<tr>
										<th>Plan</th>
										<th>Credits</th>
										<th>Expired on</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(expiredPlanData).map(([key, value]) => (
										<tr key={key}>
											<td><p className="m-0"><b>{(value.customer_membership_plan !== null && typeof value.customer_membership_plan !== 'undefined' && typeof value.customer_membership_plan.membership_plan !== 'undefined' && value.customer_membership_plan.membership_plan !== null) ? ucwords(value.customer_membership_plan.membership_plan.name) : ''}</b></p></td>
											<td><p className="m-0">{(value.customer_membership_plan !== null && value.customer_membership_plan.all_credits !== null) ? `${value.customer_membership_plan.remaining_credits}/${value.customer_membership_plan.all_credits}` : (value.customer_membership_plan !== null && value.customer_membership_plan.all_credits === null) ? 'Unlimited credits' : ''} </p></td>
											<td><p className="m-0">{(value.customer_membership_plan !== null) ? moment(value.customer_membership_plan.end_date).format('D MMM YYYY') : ''}</p></td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</React.Fragment>
				) : null}
				{!loading && Object.keys(expiredPlanData).length === 0 ? (
					<div className="row">
						<div className="col-md-12">
							<h3 className="text-center">No expired plans found.</h3>
						</div>
					</div>
				) : null}
			</React.Fragment>
		)
	}

	const addChildModal = () => {
		return (
			<React.Fragment>
				<Modal show={showModal} onHide={handleModalClose}>
					<Modal.Header closeButton>
						<Modal.Title>Add Child to Membership Plan</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Are you sure you want to add the selected child to this membership plan?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleConfirm}>
							Confirm
						</Button>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Memberships</h1>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="memberships-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar />
							</div>
							<div className="col-md-9">
								<div className="table-responsive mt-3" id="memberships-table">
									{renderActivePlans()}
								</div>
								<hr />
								<div className="table-responsive mt-3" id="memberships-table">
									{renderExpiredPlan()}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{addChildModal()}
		</React.Fragment>
	)
}

export default MembershipPage
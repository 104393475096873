/*
* @Author: abhi
* @Date:   2024-06-04 16:54:00
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 17:51:29
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, clearData } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import EventServices from '../../services/EventServices';
import CommonService from '../../services/CommonServices';
import moment from 'moment';
import { showErrorMessage } from '../../helpers/flash-messages';

const AppointmentListPage = () => {
	const formData = {};
	const [loading, setLoading] = useState(true);
	const [resultData, setResultData] = useState({});
	const [filterData, setFilterData] = useState({});
	const [companyData, setCompanyData] = useState({});
	const [searchFilters, setSearchFilters] = useState({});
	const [requestDate, setRequestDate] = useState(moment().format('YYYY-MM-DD'));
	const [categoryFilter, setCategoryFilter] = useState({});
	const [coachFilter, setCoachFilter] = useState({});
	const [roomFilter, setRoomFilter] = useState({});
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedCoach, setSelectedCoach] = useState("");
	const [selectedRoom, setSelectedRoom] = useState("");
	const eventService = new EventServices();
	const commonService = new CommonService();
	const navigate = useNavigate();
	useEffect(() => {
		setCurrentUrl('appointments');
  		seo({
  			title: SITE_TITLE + ' || Appointments',
  			metaDescription: '',
  		});
  		getSearchFilter();
  		getEventData();
  	},[]);

  	useEffect(() => {
        filterSchedule();
    }, [selectedCategory, selectedRoom, selectedCoach]);

	const getSearchFilter = () => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
			company_data = JSON.parse(company_data);
  			setCompanyData(company_data);
  			if(typeof company_data.id !== 'undefined' && typeof company_data.location.id !== 'undefined') {
			  	var searchRequest = {company_id:company_data.id, location_id:company_data.location.id, source: 'web', filter_for:'class'};
			  	commonService.searchFilter(searchRequest).then(response => {
			  		if(response.data.status === 'success') {
			  			setCategoryFilter(response.data.data.category_filter);
			  			setCoachFilter(response.data.data.coach_filter);
			  			setRoomFilter(response.data.data.room_filter);
			  		}
			  	});
			}
		}
	}

	const handleFilterChange = (filter_type, event) => {
		if(filter_type === 'category') {
			setSelectedCategory(event.target.value);
		} else if(filter_type === 'coach') {
			setSelectedCoach(event.target.value);
		} else if(filter_type === 'room') {
			setSelectedRoom(event.target.value);
		}
	};

	const filterSchedule = (data=null) => {
	    const filteredSchedule = {};
	    let category_id = selectedCategory !== "Category" ? selectedCategory : null;
	    let coach_id = selectedCoach !== "Coach" ? selectedCoach : null;
	    let room_id = selectedRoom !== "Room" ? selectedRoom : null;
	    let schedule = data !== null ? data : resultData;
	    Object.keys(schedule).forEach((date) => {
	        filteredSchedule[date] = { ...schedule[date], data: [] };

	        filteredSchedule[date].data = schedule[date].data.filter((item) => {
	            const matchesCategory = category_id ? item.category_id == category_id : true;
	            const matchesRoom = room_id ? item.room_id == room_id : true;
	            const matchesCoach = coach_id ? item.coach_id == coach_id : true;
	            const isNotDeleted = item.is_deleted !== 1;
	            return matchesCategory && matchesRoom && matchesCoach && isNotDeleted;
	        }).sort((a, b) => moment(a.start_time, 'HH:mm').diff(moment(b.start_time, 'HH:mm')));
	    });
	    setFilterData(filteredSchedule);
	};

	const getEventData = (date=null) => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
			company_data = JSON.parse(company_data);
  			setCompanyData(company_data);
  			if(typeof company_data.id !== 'undefined' && typeof company_data.location.id !== 'undefined') {
			  	formData.company_id = company_data.id;
			  	formData.location_id = company_data.location.id;
			  	formData.source = 'web';
			  	formData.date = (date !== null) ? date : requestDate;
			  	formData.event_type = 'appointment';
			  	eventService.getEvents(formData).then(response => {
			  		setLoading(false);
		  			if(response.data.status === 'success') {
		  				setResultData(response.data.data);
		  				filterSchedule(response.data.data);
		  			} else {
		  				if(response.data.error === 'refresh_data') {
		  					showErrorMessage(response.data.message);
		  					clearData('invalid_company');
		  					navigate(`${INDEX_URL}`);
		  				}
		  			}
		  		});
			}
		}
	}

	const handleClearFilters = () => {
		setSelectedCategory('');
		setSelectedCoach('');
		setSelectedRoom('');
	}

	const getEvents = (dataType) => {
		setLoading(true);
		if(dataType === 'prev') {
			var requestDate = moment(Object.keys(filterData)[0]).subtract(1, 'days').format('YYYY-MM-DD');
		} else {
			var requestDate = moment(Object.keys(filterData).sort().pop()).add(1, 'days').format('YYYY-MM-DD');
		}
		getEventData(requestDate);
	}

	const dataLength = (data) => {
		let maxDataLength = 0;
		maxDataLength = Object.values(data).reduce((maxLength, currentDay) => {
			const currentDayLength = currentDay.data.length;
			return Math.max(maxLength, currentDayLength);
		}, 0);
		return maxDataLength;
	}

	const renderSearchForm = () => {
		return (
			<React.Fragment>
				<form id="classes-form" className="commonFromTop">
					<div className="row">
						<div className="col-md-3">	
							<div className="mb-3">
								<select className="form-select" aria-label="Default select example" value={selectedCategory} onChange={(event)=> handleFilterChange('category',event)}>
									<option value="" selected>Category</option>
									{Object.keys(categoryFilter).length > 0 ? (
						                Object.entries(categoryFilter).map(([index, value]) => (
						                    <option key={index} value={index}>{value}</option>
						                ))
						            ) : (
						                <option disabled>No categories available</option>
						            )}
								</select>
							</div>	
						</div>
						<div className="col-md-3">	
							<div className="mb-3">
								<select className="form-select" aria-label="Default select example" value={selectedCoach} onChange={(event)=> handleFilterChange('coach',event)}>
									<option value="" selected>Coach</option>
									{Object.keys(coachFilter).length > 0 ? (
						                Object.entries(coachFilter).map(([index, value]) => (
						                    <option key={index} value={index}>{value}</option>
						                ))
						            ) : (
						                <option disabled>No coach available</option>
						            )}
								</select>
							</div>	
						</div>
						<div className="col-md-3">	
							<div className="mb-3">
								<select className="form-select" aria-label="Default select example" value={selectedRoom} onChange={(event)=> handleFilterChange('room',event)}>
									<option value="" selected>Location</option>
									{Object.keys(roomFilter).length > 0 ? (
						                Object.entries(roomFilter).map(([index, value]) => (
						                    <option key={index} value={index}>{value}</option>
						                ))
						            ) : (
						                <option disabled>No location available</option>
						            )}
								</select>
							</div>	
						</div>
						<div className="col-md-3">	
							<div className="mb-3">
								<button type="button" className="btn btn-primary" onClick={() => handleClearFilters()}>Clear All Filters</button>
							</div>	
						</div>
					</div>													
				</form>
			</React.Fragment>
		);
	}

	const renderAppointments = () => {
		return (
			<React.Fragment>
				{loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
	            {!loading && Object.keys(filterData).length > 0 ? (
	            	<table className="table table-bordered commonTableData">
						<thead>
							<tr>
								{Object.entries(filterData).map(([key, value], index) => (
								    <th scope="col" key={index}>
								        {moment(key).isValid() ? moment(key).format('MMM DD') : key}<br/>{moment(key).isValid() ? moment(key).format('ddd') : key}
								    </th>
								))}
							</tr>
						</thead>
						<tbody>
							{[...Array(dataLength(filterData))].map((_, rowIndex) => (
		                        <tr key={rowIndex}>
								  {Object.entries(filterData).map(([key1, value1], index1) => {
								    const rowData = filterData[key1]['data'][rowIndex];
								    if (typeof rowData !== 'undefined') {
								      const id = rowData['id'];
								      const startTime = rowData['start_time'];
								      const endTime = rowData['end_time'];

								      const encodedData = btoa(`${key1}-${id}-${startTime}-${endTime}`);
								      return (
								        <td key={index1}>
										  <Link
											  to={`${SITE_URL}appointment-detail/${encodedData}`}
											  className={rowData['is_passed'] || rowData['is_fulled'] ? 'disabled' : ''}
											  tabIndex={rowData['is_passed'] || rowData['is_fulled'] ? '-1' : undefined}
											>
										    <p className={rowData['is_passed'] || rowData['is_fulled'] ? 'm-0 grayTxt' : 'm-0 redTxt'}>
										      <strong>{rowData['name']}</strong>
										    </p>
										    <p className={rowData['is_passed'] || rowData['is_fulled'] ? 'm-0 grayTxt' : 'm-0'}>
										      {rowData['room']} {moment(rowData['start_time'], "HH:mm").format("hh:mm A")} - {rowData['duration']}
										    </p>
										  </Link>
										</td>
								      );
								    } else {
								      return <td key={index1}></td>;
								    }
								  })}
								</tr>
		                    ))}
						</tbody>	
					</table>
	            ) : null}
	            {!loading && Object.keys(filterData).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <p className="text-center">
	                            <h3>Classes not available.</h3>
	                        </p>
	                    </div>
	                </div>
	            ) : null}
	        </React.Fragment>
		)
	} 

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Appointments</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="classes-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-12">	
								{renderSearchForm()}	
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-6">	
								<a href="#" className="left-arrow arrow-icon" onClick={() => getEvents('prev')}><i className="fa-solid fa-circle-chevron-left fa-fw"></i></a>
							</div>
							<div className="col-6">	
								<a href="#" className="right-arrow arrow-icon" onClick={() => getEvents('next')}><i className="fa-solid fa-circle-chevron-right fa-fw"></i></a>
							</div>
							<div className="col-md-12 mt-3">
								<div className="table-responsive" id="classes-table">
									{renderAppointments()}
								</div>

							</div>
						</div>	
					</div>
				</section>
			</main>
		</React.Fragment>
	);
}
export default AppointmentListPage;
/*
* @Author: abhi
* @Date:   2024-06-20 15:36:02
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 18:32:47
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, ucwords, clearData } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import ChildServices from '../../services/ChildServices';
import UserSidebar from '../../components/elements/UserSidebar';
import DeleteModal from '../../components/elements/DeleteModal';
import moment from 'moment';
import { showErrorMessage } from '../../helpers/flash-messages';

const ChildrenPage = () => {
	const [authUser, setAuthUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [children, setChildren] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState('');
	const [childId, setChildId] = useState('');
	const [requestData, setRequestData] = useState({id: null, message: null});
	const userServices = new UserServices();
	const childServices = new ChildServices();
	const navigate = useNavigate();

	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
		}
		seo({
	      title: SITE_TITLE+' || Children',
	      metaDescription: '',
	    });
		setCurrentUrl(`children`);
		sessionStorage.setItem('active_menu','children');

	},[]);

	useEffect(() => {
		getChildren();
	},[authUser]);

	
	const getChildren = () => {
		if(typeof authUser.auth_token !== 'undefined' && authUser.auth_token !== '') {
			let data = {company_id:authUser.company_id, location_id:authUser.location_id};
			childServices.getChildren(authUser.auth_token, data).then(response => {
				setLoading(false);
				if(response.data.status === 'success') {
					setChildren(response.data.data);
				} else {
					if(response.data.error === 'no_data_error') {
						setChildren({});
					}
					if(response.data.error === 'authentication_error') {
						localStorage.removeItem("auth_user");
						sessionStorage.removeItem("current_url");
						window.location.href = INDEX_URL;
					}
					if(response.data.error === 'refresh_data') {
                        showErrorMessage(response.data.message);
                        clearData('invalid_company');
                        navigate(`${INDEX_URL}`);
                    }
				}
			});
		}
	}

	const confirmationModal = (id) => {
		setChildId(id);
		setDeleteMessage('Do you really want to delete this child ?');
		setShowDeleteModal(true);
    }

    const handleCloseModal = () => {
        setShowDeleteModal(false);
    };

	const renderChildren = () => {
		return (
			<React.Fragment>
				{loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
	            {!loading && Object.keys(children).length > 0 ? (
	            	<React.Fragment>
	            		<div className="table-container">
		            		<table className="table-scroll">
		                		<thead>
		                			<tr>
		                				<th></th>
		                				<th>Child</th>
		                				<th>Date of birth</th>
		                				<th>Action</th>
		                			</tr>
		                		</thead>
		                		<tbody>
				                    {Object.entries(children).map(([key, value]) => (
				                        <tr key={key}>
				                        	<td style={{width:"10%"}}><img src={value.profile_image} /></td>
				                        	<td><b>{value.first_name} {value.last_name}</b><br/>{value.email}</td>
				                        	<td>{ (value.date_of_birth !== 'undefined' && value.date_of_birth !== null && value.date_of_birth !== '') ? moment(value.date_of_birth).format('DD MMMM YYYY') : null }</td>
				                        	<td><i className="fa-solid fa-trash-can btn text-danger" onClick={(e) => confirmationModal(value.id)}></i></td>
				                        </tr>
				                    ))}
				                </tbody>
				            </table>
				        </div>
	            	</React.Fragment>
	            ) : null }
	            {!loading && Object.keys(children).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <h3 className="text-center">No children found.</h3>
	                    </div>
	                </div>
	            ) : null}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Children</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="my-bookings-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								<div className="row">
									<div className="col-md-8 col-sm-8 col-xl-9 col-lg-9 align-self-center"><h4>Children</h4></div>
									<div className="col-md-4 col-sm-4 col-xl-3 col-lg-3">
										<Link to={`${SITE_URL}add-child`}>
											<button className="btn btn-primary" type="button">Add Child</button>
										</Link>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-md-12">
										
										<div className="table-responsive mt-3" id="my-bookings-table">
											{renderChildren()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{showDeleteModal && (
                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered centered" role="document">
                        <div className="modal-content">
                            <DeleteModal requestData={{ ...requestData, id: childId, message: deleteMessage  }} showModal={showDeleteModal} onClose={handleCloseModal} onDeleteSuccess={getChildren} />
                        </div>
                    </div>
                </div>
            )}
		</React.Fragment>
	)
}

export default ChildrenPage;
/*
* @Author: abhishek714
* @Date:   2024-04-10 15:47:40
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-15 11:15:05
*/
import React from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import {INDEX_URL} from '../settings/common';
import IndexPage from '../pages/Main/IndexPage';
import RegisterPage from '../pages/User/RegisterPage';
import LoginPage from '../pages/User/LoginPage';
import ProfilePage from '../pages/User/ProfilePage';
import ProductPage from '../pages/Product/ListPage';
import ProductDetailPage from '../pages/Product/DetailPage';
import ChangePasswordPage from '../pages/User/ChangePasswordPage';
import ForgotPasswordPage from '../pages/User/ForgotPasswordPage';
import BookingPage from '../pages/User/BookingPage';
import MembershipPage from '../pages/User/MembershipPage';
import PaymentPage from '../pages/User/PaymentPage';
import PaymentMethodPage from '../pages/User/PaymentMethodPage';
import ChildrenPage from '../pages/User/ChildrenPage';
import AddChildPage from '../pages/User/AddChildPage';
import MembershipPlanPage from '../pages/MembershipPlan/ListPage';
import CheckOutPage from '../pages/Payment/CheckOutPage';
import CartPage from '../pages/Payment/CartPage';
import ClassListPage from '../pages/Classes/ListPage';
import ClassDetailPage from '../pages/Classes/DetailPage';
import AppointmentListPage from '../pages/Appointments/ListPage';
import AppointmentDetailPage from '../pages/Appointments/DetailPage';

const PrivateRoutes = () => {
    const auth_user = localStorage.getItem('auth_user');
    return(
        auth_user ? <Outlet/> : <Navigate to="/login"/>
    )
}

const AuthRoutes = () => {
    const auth_user = localStorage.getItem('auth_user');
    return(
        auth_user ? <Navigate to="/profile"/> : <Outlet/>
    )
}

const ClientRoutes = () => {
	const company_data = localStorage.getItem('company_data');
    return(
        company_data ? <Outlet/> : <Navigate to="/"/>
    )
}


const Routings = () => {
	return(
			
			<Routes>
				<Route exact path={INDEX_URL} element={<IndexPage/>} />
				<Route exact path={`${INDEX_URL}/:operation_status/:operation_type`} element={<IndexPage/>} />
				<Route element={<PrivateRoutes />}>
					<Route exact path={INDEX_URL+'profile'} element={<ProfilePage/>} />
					<Route exact path={INDEX_URL+'change-password'} element={<ChangePasswordPage/>} />
					<Route exact path={INDEX_URL+'bookings'} element={<BookingPage/>} />
					<Route exact path={INDEX_URL+'memberships'} element={<MembershipPage/>} />
					<Route exact path={INDEX_URL+'payments'} element={<PaymentPage/>} />
					<Route exact path={INDEX_URL+'payment-methods'} element={<PaymentMethodPage/>} />
					<Route exact path={INDEX_URL+'children'} element={<ChildrenPage/>} />
					<Route exact path={INDEX_URL+'add-child'} element={<AddChildPage/>} />
				</Route>
				<Route element={<AuthRoutes />}>
					<Route exact path={INDEX_URL+'register'} element={<RegisterPage/>} />
					<Route exact path={INDEX_URL+'login'} element={<LoginPage/>} />
					<Route exact path={INDEX_URL+'forgot-password'} element={<ForgotPasswordPage/>} />
				</Route>
				<Route element={<ClientRoutes />}>
					<Route exact path={INDEX_URL+'shop'} element={<ProductPage/>} />
					<Route path={INDEX_URL+'shop/page/:pageNumber'} element={<ProductPage />} />
					<Route path={INDEX_URL+'product/:slug'} element={<ProductDetailPage />} />
					<Route exact path={INDEX_URL+'pricing'} element={<MembershipPlanPage/>} />
					<Route path={INDEX_URL+'check-out/:slug'} element={<CheckOutPage />} />
					<Route path={INDEX_URL+'cart/'} element={<CartPage />} />
					<Route exact path={INDEX_URL+'classes'} element={<ClassListPage/>} />
					<Route exact path={INDEX_URL+'class-detail/:slug'} element={<ClassDetailPage/>} />
					<Route exact path={INDEX_URL+'appointments'} element={<AppointmentListPage/>} />
					<Route exact path={INDEX_URL+'appointment-detail/:slug'} element={<AppointmentDetailPage/>} />
				</Route>
			</Routes>
			
		)
}
export default Routings;
/*
* @Author: abhishek714
* @Date:   2024-05-01 15:47:57
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:36:09
*/
import React, { useState, useEffect, useContext } from 'react';
import { Link  } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, PAYMENT_GATEWAY_URL } from '../../settings/common';
import { seo } from '../../settings/seo';
import { AddToCart, removeItem, cartItemCounts } from '../../helpers/cart';
import CommonServices from '../../services/CommonServices';
import OrderServices from '../../services/OrderServices';
import LoginModal from '../../components/elements/LoginModal';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import { AppContext } from '../../components/elements/AppContext';

const CheckOutPage = () => {
	const [companyData, setCompanyData] = useState({});
	const [authUser, setAuthUser] = useState({});
	const [isLogin, setIsLogin] = useState(0);
	const [cartData, setCartData] = useState({});
	const [cartItems, setCartItems] = useState({});
	const [formData, setFormData] = useState({});
	const [amount, setAmount] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [taxAmount, setTaxAmount] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [couponActive, setCouponActive] = useState(null);
	const [loading, setLoading] = useState(false);
	const { setSharedCartCount, SharedAuthUser } = useContext(AppContext);
	const commonService = new CommonServices();
	const orderService = new OrderServices();
	
	useEffect(() => {
		seo({
			title: SITE_TITLE + ' || Cart',
			metaDescription: '',
		});

		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
			setIsLogin(1);
		}
		
		let companyDataFromStorage = localStorage.getItem('company_data');
	    if (companyDataFromStorage !== undefined && companyDataFromStorage !== null) {
	        const data = JSON.parse(companyDataFromStorage);
	        setCompanyData(data);
	    }
	    let cart_data = sessionStorage.getItem('cart_data');
		showCartData();
  		setCurrentUrl(`cart`);

  	},[]);

  	useEffect(() => {
  		if(typeof SharedAuthUser.id !== 'undefined') {
  			setIsLogin(1);
  			setAuthUser(SharedAuthUser);
  		}
  	},[SharedAuthUser]);

  	const showCartData = () => {
  		let cart_data = sessionStorage.getItem('cart_data');
		if(typeof cart_data !== 'undefined' && cart_data !== null) {
			cart_data = JSON.parse(cart_data);
			setCartData(cart_data);
  			setCartItems(cart_data.items);
  			setAmount(cart_data.price);
  			setTotalAmount(cart_data.price);
  			setTaxAmount(0);
  		} else {
  			setCartData({});
  			setCartItems({});
  			setAmount(0);
  			setTotalAmount(0);
  			setTaxAmount(0);
  		}
  	}

  	const handleCouponChange = (e) => {
  		const { name, value } = e.target;
	    const newFormData = {
	    	...formData,
	    	[name]: value,
	    };
	    setFormData(newFormData);
	};

	const checkCouponCode = () => {
		formData.location_id = companyData.location.id;
		formData.company_id = companyData.id;
		formData.amount = amount;
		var discount_amount = 0;
		if(typeof authUser.auth_token !== 'undefined') {
			commonService.checkCouponCode(formData, authUser.auth_token).then(response => {
				let result = response.data;
				if(result.status === 'success') {
					if(result.data.is_active === 'active') {
						if(result.data.discount_for == 'credits') {
							setCouponActive(0);
							showErrorMessage('Sorry, this coupon code is not applicable to your current selection or purchase.');
						} else {
						discount_amount = (result.data.discount_for === 'rates') ? result.data.discount_amount : 0;
							setCouponActive(1);
							(result.data.discount_for === 'rates') ? showSuccessMessage('Coupon code applied successfully.') : showSuccessMessage('Coupon code applied successfully. '+result.data.discount_amount+' credit will be credit in account soon.');
						}
					} else {
						setCouponActive(0);
						showErrorMessage('Invalid or expired coupon code.');
					}
				}
				setDiscountAmount(discount_amount);
				getPrices(discount_amount);
				
			});
		} else {
			setIsLogin(0);
			setLoading(false);
			setShowModal(true);
		}
	}

	const getPrices = (discount_amount=0) => {
		var totalAmount = ((amount + taxAmount) - discount_amount);
		setTotalAmount(totalAmount);
	}

	const handleCouponKeyup = () => {
		setDiscountAmount(0.00);
		setCouponActive(null);
		getPrices();
	}

	const handleRemoveCoupon = () => {
		formData.coupon_code = '';
		setFormData(formData);
		setDiscountAmount(0.00);
		setCouponActive(null);
		getPrices();
	}

	const handlePurchase = () => {
		if (!isLogin) {
            setShowModal(true);
        } else {
			setLoading(true);
			formData.order_type = 'product';
			formData.order_item = cartData.items;
			orderService.addOrder(formData, authUser.auth_token).then(response => {
				let result = response.data;
				if(result.status === 'success') {
					sessionStorage.removeItem('current_url');
					sessionStorage.removeItem('active_menu');
					window.location.href = PAYMENT_GATEWAY_URL+'order?auth_token='+authUser.auth_token+'&order_id='+result.data.order_id+'&source=web&operation_type=payment';
				} else {
					setIsLogin(0);
					setLoading(false);
					setShowModal(true);
				}
			});
		}
	}

	const handleCloseModal = () => {
        setShowModal(false);
    };

    const changeQuantity = (product_id,action) => {
    	let product = cartData.items.filter(product => product.product_id === product_id);
    	product = product[0]
    	let quantity = (typeof product.quantity !== 'undefined' && product.quantity !== '') ? product.quantity : 1;
    	if(action === 'plus' && quantity < product.remaining_quantity) {
    		quantity = quantity+1;
    	} else if(quantity > 0 && action === 'minus') {
    		quantity = quantity-1;
    	}
    	product.quantity = quantity;
    	AddToCart(product);
    	showCartData();
	}

	const handleQunatityChange = (e,product_id) => {
		const { name, value } = e.target;
		let product = cartData.items.filter(product => product.product_id === product_id);
    	product = product[0]
    	product.quantity = value;
    	AddToCart(product);
    	showCartData();
	}

    const hadleRemoveItem = (item_id) => {
    	removeItem(item_id);
    	handleRemoveCoupon();
    	showCartData();
    	setSharedCartCount(cartItemCounts);
  		showSuccessMessage('Item removed successfully from the cart');
  	}

	const priceDetails = () => {
		let cart_data = sessionStorage.getItem('cart_data');
		let companyDataFromStorage = localStorage.getItem('company_data');
		const companyData = JSON.parse(companyDataFromStorage);
		const cartData = JSON.parse(cart_data);
		
		if(typeof cartData !== 'undefined' && typeof companyData !== 'undefined') {
			return(
				<React.Fragment>
					<div className="row">
						<div className="col-md-12">	
							<h4>Price Details</h4>
							<table className="table price-details">
								<tbody>
									<tr>
										<td>Subtotal</td>
										<td style={{textAlign:"right"}}>{companyData.location.currency_symbol}{parseFloat(amount).toFixed(2)}</td>
									</tr>
									<tr>
										<td>Discount</td>
										<td style={{textAlign:"right"}}>{companyData.location.currency_symbol}{parseFloat(discountAmount).toFixed(2)}</td>
									</tr>
									<tr>
										<td>Tax (Included)</td>
										<td style={{textAlign:"right"}}>{companyData.location.currency_symbol}{parseFloat(taxAmount).toFixed(2)}</td>
									</tr>
									<tr>
										<td style={{borderTop: "1px solid #999"}}><strong>Total Amount</strong></td>
										<td style={{textAlign:"right",borderTop: "1px solid #999"}}><strong>{companyData.location.currency_symbol}{parseFloat(totalAmount).toFixed(2)}</strong></td>
									</tr>
								</tbody>
							</table>	
						</div>
					</div>
				</React.Fragment>
			);
		}
	}

	const checkOutData = () => {
    	let cart_data = sessionStorage.getItem('cart_data');
    	let companyDataFromStorage = localStorage.getItem('company_data');
    	const companyData = JSON.parse(companyDataFromStorage);
    	const cartData = JSON.parse(cart_data);
    	if (typeof cartData !== 'undefined' && cartData !== null && typeof companyData !== 'undefined') {
        	return (
            	<React.Fragment>
                	<table className="table">
                    	<thead>
                        	<tr>
                            	<th>Product Details</th>
	                            <th>Quantity</th>
	                            <th>Price</th>
	                            <th>Total Price</th>
	                        </tr>
	                    </thead>
	                    <tbody>
                    		{(typeof cartData.items !== 'undefined' && cartData.items !== null && Object.keys(cartData.items).length > 0) ?
                            	Object.entries(cartData.items).map(([key, value]) => (
                                	<tr key={key}>
                                    	<td><img src={value.product_image}/>
                                    		<h6>{value.product_name}</h6>
                                    		<span onClick={() => hadleRemoveItem(value.product_id)} className="redTxt" style={{ cursor :'pointer'}}>Remove</span>
                                    	</td>
                                    	<td>
                                    		<div className="input-group quantity">
	                                    		<span className="input-group-btn">
	                                    			<button
												      type="button"
												      className="quantity-left-minus btn btn-number"
												      data-type="minus"
												      data-field=""
												      onClick={() => changeQuantity(value.product_id,'minus')}
												    >
												      <span><i className="fa-solid fa-minus fa-fw"></i></span>
												    </button>
												</span>
												<input
												    type="text"
												    id="quantity"
												    name="quantity"
												    className="input-number"
												    min="1"
												    max={value.remaining_quantity}
												    value={value.quantity}
												    onChange={(e) => handleQunatityChange(e,value.product_id)}
												/>
												<span className="input-group-btn">
												    <button
												      type="button"
												      className="quantity-right-plus btn btn-number"
												      data-type="plus"
												      data-field=""
												      onClick={() => changeQuantity(value.product_id,'plus')}
												    >
											        <i className="fa-solid fa-plus fa-fw"></i>
											        </button>
											    </span>
											</div>
										</td>
                                    	<td>{companyData.location.currency_symbol}{parseFloat(value.product_amount).toFixed(2)}</td>
                                    	<td>{companyData.location.currency_symbol}{(value.quantity * value.product_amount).toFixed(2)}</td>
                                	</tr>
                            	))
                            	:
                            	<tr>
                                	<td colSpan="4">No items in the cart</td>
                            	</tr>
                        	}
                    	</tbody>
                	</table>
            	</React.Fragment>
        	);
	    } else {
	        return (
	        	<React.Fragment>
	        		<div className="text-center">
	        			<h3>No items in the cart</h3>
	        		</div>
	        	</React.Fragment>
	        )
	    }
	};


	const discountDiv = () => {
		return(
			<React.Fragment>
				<div className="row">
					<div className="col-md-12">	
						<label htmlFor="discountCode">Discount Code</label>
						<div className="input-group">						
							<input className="form-control border-end-0" type="search" placeholder="Enter Code" id="coupon_code" name="coupon_code" value={formData.coupon_code || null} onChange={handleCouponChange} onKeyUp={() => handleCouponKeyup()}/>
							{(couponActive) ? (
								<span className="input-group-append">
									<button className="btn btn-outline-secondary" type="button" onClick={()=>handleRemoveCoupon()}>
										Remove
									</button>
								</span>
							) : 
							(
								<span className="input-group-append">
									<button className="btn btn-outline-secondary" type="button" onClick={()=>checkCouponCode()}>
										Apply
									</button>
								</span>
							)}
						</div>
						{(couponActive && couponActive !== null) ? <small className="greenTxt">Coupon code applied successfully.</small> : (!couponActive && couponActive !== null) ? <small className="redTxt">Invalid or expired coupon code.</small> : ''}
					</div>
				</div>
				<hr/>
			</React.Fragment>	
		)
	}
	
	return(
		<React.Fragment>
			{loading && (
		        <div className="overlay">
		          <div className="loading">
		            <div className="spinner"></div>
		          </div>
		        </div>
		      )}
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Cart</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="cart-section common-padding">
					<div className="container">
						<div className="row">
							<div className={(typeof cartData !== 'undefined' && Object.keys(cartData).length > 0) ? 'col-md-12 col-sm-12 col-xl-8 col-lg-8' : 'col-md-12 col-sm-12 col-xl-12 col-lg-12'}>	
								<div className="row">
									<div className="col-9">	
										<h4>Shopping Cart</h4>
									</div>
									<div className="col-3">	
										<h4 className="text-right">{(typeof cartData.items !== 'undefined') ? (Object.keys(cartData.items).length > 1) ? `${Object.keys(cartData.items).length} items` : `${Object.keys(cartData.items).length} item`  : null}</h4>
									</div>
								</div>
								<hr/>
								<div className="row">
									<div className="col-md-12">
										<div className="table-responsive" id="cartTable">
											{ checkOutData() }
										</div>
									</div>
								</div>		

							</div>
							{(typeof cartData !== 'undefined' && Object.keys(cartData).length > 0) ? 
								<div className="col-md-12 col-sm-12 col-xl-4 col-lg-4">	
									<div className="cartSideDiv">
										<div className="row">
											<div className="col-md-12">	
												<h4>Order Summary</h4>
											</div>
										</div>
										<hr/>
										{ discountDiv() }	
										{ priceDetails() }
										<div className="row">
											<div className="col-md-12">	
												<button type="button" className="btn btn-primary" id="confirm-purchase-btn" onClick={()=>handlePurchase()} disabled={loading}>{loading ? 'Processing...' : 'Confirm Purchase'}</button>
											</div>
										</div>	
									</div>	
								</div>
							: null}
						</div>
					</div>
				</section>
			</main>
			{showModal && (
                <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered centered" role="document">
                        <div className="modal-content">
                            <LoginModal showModal={showModal} onClose={handleCloseModal} />
                        </div>
                    </div>
                </div>
            )}
		</React.Fragment>
	)

}
export default CheckOutPage;
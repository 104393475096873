/*
* @Author: abhi
* @Date:   2024-06-11 20:06:35
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:17:33
*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SITE_TITLE, PAYMENT_GATEWAY_URL, INDEX_URL } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import UserSidebar from '../../components/elements/UserSidebar';

const PaymentMethodPage = () => {
	const [authUser, setAuthUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [paymentMethodData, setPaymentMethodData] = useState({});
	const userServices = new UserServices();
	
	
	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if (auth_user) {
			setAuthUser(JSON.parse(auth_user));
		}
	}, []); // Empty dependency array means this effect runs only once on mount

  	useEffect(() => {
  		if (authUser.auth_token) { // Check if auth_token is available
  			getPaymentMethod(authUser.auth_token);
  		}
  	}, [authUser]); // Run this effect whenever authUser changes

  	useEffect(() => {
  		// This useEffect can be used for other page-related effects
	    seo({
	      title: SITE_TITLE + ' || Payment Methods',
	      metaDescription: 'Your meta description here', // Add your meta description
	    });
	    setCurrentUrl('payment-methods');
	    sessionStorage.setItem('active_menu', 'payment-methods');
	}, []);

	const getPaymentMethod = (auth_token) => {
		userServices.getPaymentMethod(auth_token).then(response => {
			if(response.data.status === 'success') {
				setPaymentMethodData(response.data.data.payment_method);
				setLoading(false);
			} else {
				if(response.data.error === 'authentication_error') {
					localStorage.removeItem("auth_user");
					sessionStorage.removeItem("current_url");
					window.location.href = INDEX_URL;
				}
			}
		});
	}
	
	const renderData = () => {
		return (
			<React.Fragment>
				{!loading && Object.keys(paymentMethodData).length > 0 ? (
	                <React.Fragment>
	                	<div className="table-container">
	                		{Object.entries(paymentMethodData).map(([key, value]) => (
								<React.Fragment key={key}>
									<div className="savedBox">
										<div className="row">
											<div className="col-md-6">
												<div className="form-check">
													<label className="form-check-label" htmlFor="flexRadioDefault2">
														<img src={value.icon} alt={value.card_brand} className="img-fluid d-block" />
													</label>
												</div>
											</div>
											<div className="col-md-6 align-self-center">
												<div className="text-right">
													<h5 className="grayTxt">**** **** **** {value.last_four}</h5>
													<h6 className="m-0 grayTxt">{value.card_brand}</h6>
												</div>	
											</div>
										</div>
									</div>
								</React.Fragment>
							))}
						</div>
	                </React.Fragment>
	            ) : null}
	            {!loading && Object.keys(paymentMethodData).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <p className="text-center">
	                            <h3>Payment methods not available.</h3>
	                        </p>
	                    </div>
	                </div>
	            ) : null}
	        </React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Payment Methods</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="payment-method-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								<div className="row">
									<div className="col-md-8 col-sm-8 col-xl-9 col-lg-9 align-self-center"><h4>Saved Payment Methods</h4>	
									</div>
									<div className="col-md-4 col-sm-4 col-xl-3 col-lg-3">
										<Link to={`${PAYMENT_GATEWAY_URL}add-card/?auth_token=${authUser.auth_token}&source=web&operation_type=add-new-card`} className="btn btn-primary">Add new card</Link>	
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-md-12">	
										
										{loading && (
							                <div className="row">
							                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
							                        <div className="spinner"></div>
							                    </div>
							                </div>
							            )}
							            {renderData()}
							        </div>
							    </div>
					        </div>
						</div>
					</div>
				</section>
			</main>  
		</React.Fragment>
	)

}

export default PaymentMethodPage
/*
* @Author: abhishek714
* @Date:   2024-04-23 12:50:38
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:18:43
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

class ProductService {
	async getProducts(data){
		const url = `${API_URL}get-products`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async getProductDetails(data){
		const url = `${API_URL}get-product-details`;
		const headers = {
			'Content-Type': 'application/json'
		};
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

}
export default ProductService;
/*
* @Author: abhi
* @Date:   2024-04-27 16:42:30
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:35:00
*/
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SITE_TITLE, PAYMENT_GATEWAY_URL, firstChar, renderHTML } from '../../settings/common';
import { seo } from '../../settings/seo';
import CommonServices from '../../services/CommonServices';
import OrderServices from '../../services/OrderServices';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const CheckOutPage = () => {
	const { slug } = useParams();
	const [companyData, setCompanyData] = useState({});
	const [authUser, setAuthUser] = useState({});
	const [cartData, setCartData] = useState({});
	const [formData, setFormData] = useState({});
	const [amount, setAmount] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [taxAmount, setTaxAmount] = useState(0);
	const [couponActive, setCouponActive] = useState(null);
	const [loading, setLoading] = useState(false);
	const commonService = new CommonServices();
	const orderService = new OrderServices();
	
	useEffect(() => {
		seo({
			title: SITE_TITLE + ' || Checkout',
			metaDescription: '',
		});

		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
		}
		
		let companyDataFromStorage = localStorage.getItem('company_data');
	    if (companyDataFromStorage !== undefined && companyDataFromStorage !== null) {
	        const data = JSON.parse(companyDataFromStorage);
	        setCompanyData(data);
	    }
	    let cart_data = localStorage.getItem(`${slug}`);
		if(typeof cartData !== 'undefined') {
			cart_data = JSON.parse(cart_data);
			setCartData(cart_data);
  			setAmount(cart_data.price);
  			setTotalAmount(cart_data.price);
  			setTaxAmount(0);
  		}
  	},[]);

  	const handleChange = (e) => {
  		const { name, value } = e.target;
	    const newFormData = {
	    	...formData,
	    	[name]: value,
	    };
	    setFormData(newFormData);
	};

	const checkCouponCode = () => {
		formData.location_id = companyData.location.id;
		formData.company_id = companyData.id;
		formData.amount = amount;
		var discount_amount = 0;
		commonService.checkCouponCode(formData, authUser.auth_token).then(response => {
			let result = response.data;
			if(result.status === 'success') {
				if(result.data.is_active === 'active') {
					if(result.data.discount_for == 'credits') {
						setCouponActive(1);
						let credits = parseInt(result.data.discount_amount, 10);
						let message = `Your coupon code has been applied successfully, and you've received ${credits > 1 ? credits + ' credits' : credits + ' credit'}.`;
						showSuccessMessage(message)
					} else {
						discount_amount = (result.data.discount_for === 'rates') ? result.data.discount_amount : 0;
						setCouponActive(1);
						(result.data.discount_for === 'rates') ? showSuccessMessage('Coupon code applied successfully.') : showSuccessMessage('Coupon code applied successfully. '+result.data.discount_amount+' credit will be credit in account soon.');
					}
				} else {
					setCouponActive(0);
					showErrorMessage('Invalid or expired coupon code.');
				}
			}
			setDiscountAmount(discount_amount);
			getPrices(discount_amount);
			
		});
	}

	const getPrices = (discount_amount=0) => {
		var totalAmount = ((amount + taxAmount) - discount_amount);
		setTotalAmount(totalAmount);
	}

	const handleCouponKeyup = () => {
		setDiscountAmount(0.00);
		setCouponActive(null);
		getPrices();
	}

	const handleRemoveCoupon = () => {
		formData.coupon_code = '';
		setFormData(formData);
		setDiscountAmount(0.00);
		setCouponActive(null);
		getPrices();
	}

	const handlePurchase = () => {
		setLoading(true);
		if(typeof cartData.payment_method !== 'undefined' && cartData.payment_method === 'quick') {
			formData.child_id = cartData.booking_details.child_id;
			formData.order_type = cartData.booking_details.event_type;
			formData.appointment_id = cartData.booking_details.appointment_id;
			cartData.booking_details.event_id = cartData.booking_details.appointment_id;;
		} else {
			formData.order_type = 'membership';
			formData.membership_plan_id = cartData.id;
		}
		orderService.addOrder(formData, authUser.auth_token).then(response => {
			let result = response.data;
			if(result.status === 'success') {
				window.location.href = (typeof cartData.booking_details !== 'undefined' && cartData.booking_details !== null) ?
					PAYMENT_GATEWAY_URL+'order?auth_token='+authUser.auth_token+'&order_id='+result.data.order_id+'&date='+cartData.booking_details.date+'&event_id='+cartData.booking_details.event_id+'&event_type='+cartData.booking_details.event_type+'&start_time='+cartData.booking_details.start_time+'&end_time='+cartData.booking_details.end_time+'&child_id='+cartData.booking_details.child_id+'&source=web&operation_type=membership-payment' : 
					PAYMENT_GATEWAY_URL+'order?auth_token='+authUser.auth_token+'&order_id='+result.data.order_id+'&source=web&operation_type=membership-purchase';
			}
		});
	}

	const priceDetails = () => {
		let cart_data = localStorage.getItem(`${slug}`);
		let companyDataFromStorage = localStorage.getItem('company_data');
		const companyData = JSON.parse(companyDataFromStorage);
		const cartData = JSON.parse(cart_data);
		
		if(typeof cartData !== 'undefined' && typeof companyData !== 'undefined') {
			return(
				<React.Fragment>
					<div className="row">
						<div className="col-md-12">	
							<h4>Price Details</h4>
							<table className="table price-details">
								<tbody>
									<tr>
										<td>Subtotal</td>
										<td style={{textAlign:"right"}}>{companyData.location.currency_symbol}{parseFloat(amount).toFixed(2)}</td>
									</tr>
									<tr>
										<td>Discount</td>
										<td style={{textAlign:"right"}}>{companyData.location.currency_symbol}{parseFloat(discountAmount).toFixed(2)}</td>
									</tr>
									<tr>
										<td>Tax (Included)</td>
										<td style={{textAlign:"right"}}>{companyData.location.currency_symbol}{parseFloat(taxAmount).toFixed(2)}</td>
									</tr>
									<tr>
										<td style={{borderTop: "1px solid #999"}}><strong>Total Amount</strong></td>
										<td style={{textAlign:"right",borderTop: "1px solid #999"}}><strong>{companyData.location.currency_symbol}{parseFloat(totalAmount).toFixed(2)}</strong></td>
									</tr>
								</tbody>
							</table>	
						</div>
					</div>
				</React.Fragment>
			);
		}
	}

	const checkOutData = () => {
		let cart_data = localStorage.getItem(`${slug}`);
		let companyDataFromStorage = localStorage.getItem('company_data');
		const companyData = JSON.parse(companyDataFromStorage);
		const cartData = JSON.parse(cart_data);
		
		if(typeof cartData !== 'undefined' && typeof companyData !== 'undefined') {
			return(
				<React.Fragment>
					<table className="table">
						<thead>
							<tr>
								<th colSpan="2">Plan</th>
								<th></th>
								<th>Price</th>
							</tr>
						</thead>
						<tbody>
							<tr>
							
								<td colSpan="2">
									<div className="checkout-plan-list">
										<span className="square bg-danger">{firstChar(cartData.name)}</span>
										<div className="planTxt">
										<h6>{cartData.name}</h6>
										<div>{(cartData.category !== null && typeof cartData.category !== 'undefined') && cartData.category}</div>
										<div>
										  {cartData.billing_interval_count && (
										    <>
										      {(cartData.membership_type === 'recurring') && `Renews in `}
										      {cartData.billing_interval_count}{" "}
										      {cartData.billing_interval_count > 1
										        ? `${cartData.billing_interval}s`
										        : `${cartData.billing_interval}`}
										    </>
										  )}
										  </div>
										</div>
									</div>
								</td>
								<td></td>
								<td>{companyData.location.currency_symbol}{cartData.price}</td>
							</tr>
						</tbody>	
					</table>
				</React.Fragment>
			)
		}
	}

	const discountDiv = () => {
		return(
			<React.Fragment>
				<div className="row">
					<div className="col-md-12">	
						<label htmlFor="discountCode">Discount Code</label>
						<div className="input-group">						
							<input className="form-control border-end-0" type="search" placeholder="Enter Code" id="coupon_code" name="coupon_code" value={formData.coupon_code || ''} onChange={handleChange} onKeyUp={() => handleCouponKeyup()}/>
							{(couponActive) ? (
								<span className="input-group-append">
									<button className="btn btn-outline-secondary" type="button" onClick={()=>handleRemoveCoupon()}>
										Remove
									</button>
								</span>
							) : 
							(
								<span className="input-group-append">
									<button className="btn btn-outline-secondary" type="button" onClick={()=>checkCouponCode()}>
										Apply
									</button>
								</span>
							)}
						</div>
						{(couponActive && couponActive !== null) ? <small className="greenTxt">Coupon code applied successfully.</small> : (!couponActive && couponActive !== null) ? <small className="redTxt">Invalid or expired coupon code.</small> : ''}
					</div>
				</div>
				<hr/>
			</React.Fragment>	
		)
	}
	
	return(
		<React.Fragment>
			{loading && (
		        <div className="overlay">
		          <div className="loading">
		            <div className="spinner"></div>
		          </div>
		        </div>
		      )}
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Checkout</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="cart-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 col-xl-8 col-lg-8">	
								<div className="row">
									<div className="col-9">	
										<h4>Details</h4>
									</div>
									<div className="col-3">	
										<h4 className="text-right"></h4>
									</div>
								</div>
								<hr/>
								<div className="row">
									<div className="col-md-12">
										<div className="table-responsive" id="cartTable">
											{ checkOutData() }
										</div>
									</div>
								</div>		

							</div>
							<div className="col-md-12 col-sm-12 col-xl-4 col-lg-4">	
								<div className="cartSideDiv">
									<div className="row">
										<div className="col-md-12">	
											<h4>Order Summary</h4>
										</div>
									</div>
									<hr/>
									{ discountDiv() }	
									{ priceDetails() }
									<div className="row">
										<div className="col-md-12">	
											<button type="button" className="btn btn-primary" id="confirm-purchase-btn" onClick={()=>handlePurchase()} disabled={loading}>{loading ? 'Processing...' : 'Confirm Purchase'}</button>
										</div>
									</div>	
								</div>	
							</div>
						</div>
					</div>
				</section>
			</main>
		</React.Fragment>
	)

}
export default CheckOutPage;
/*
* @Author: abhishek714
* @Date:   2024-04-12 10:20:21
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:19:14
*/
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SITE_TITLE, INDEX_URL } from '../../settings/common';
import { seo } from '../../settings/seo';
import UserServices from '../../services/UserServices';
import { Validation } from '../../helpers/validation';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const LoginPage = () => {
	const [companyData, setCompanyData] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  	useEffect(() => {
  		seo({
  			title: SITE_TITLE + ' || Forgot Password',
  			metaDescription: '',
  		});
  		let company_data = localStorage.getItem('company_data');
  		if(typeof companyData !== 'undefined') {
  			setCompanyData(JSON.parse(company_data));
  		}
      setCurrentUrl('forgot-password');
  	},[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
    	...formData,
    	[name]: value,
    };
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
  	e.preventDefault();
    const errors = Validation(formData,['email']);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    submitForm();

  };

  const submitForm = async () => {
    setErrors({});
  	setLoading(true);
  	errors.apiError = '';
  	if(typeof companyData.id !== 'undefined' && typeof companyData.location.id !== 'undefined') {
  		//formData.append({company_id:companyData.id,location_id:companyData.location.id})
	  	formData.company_id = companyData.id;
	  	formData.location_id = companyData.location.id;
	  	const userServices = new UserServices();
	  	userServices.forgotPassword(formData).then(response => {
	  		let data = response.data;
	  		if(data.status === 'success') {
	  			showSuccessMessage(data.message);
          navigate(INDEX_URL+'login');
	  		} else {
          showErrorMessage(data.message);
	  		}
	  		setLoading(false);
	  	});
	 }
  };

  const renderForm = () => {
  	return (
  		<React.Fragment>
  			<form id="login-form" method="post" className="box-shadow-div" onSubmit={handleSubmit}>
  				{errors.apiError && <p className="text-danger">{errors.apiError}</p>}
  				<div className="row">
  					<div className="col-md-12">
  						<div className="input-group mb-3">
  							<span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-envelope fa-fw"></i></span>
  							<input type="text" className="form-control" id="email" placeholder="Email" name="email" value={formData.email || ''} onChange={handleChange}/>
  							{errors.email && <div className="text-danger">{errors.email}</div>}
  						</div>
  					</div>

  					<div className="col-md-12 mt-4">
  						<button type="submit" className="btn btn-primary" id="register-btn2" disabled={loading}>{loading ? 'Processing...' : 'Submit'}</button>
  						<p className="text-center grayTxt mt-1">Click here for <Link to={INDEX_URL+'login'} className="redTxt"><strong>Login</strong></Link></p>
		        </div>
		      </div>
  			</form>
	    </React.Fragment>
		);
  }

  return(
  	<React.Fragment>
  		{loading && (
        <div className="overlay">
          <div className="loading">
            <div className="spinner"></div>
          </div>
        </div>
      )}
  		<main role="main" className="main-section-area">
          <section className="header-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="default-heading text-center">
                    <h1 className="text-white m-0">Forgot Password</h1>
                  </div>
                </div>
              </div> 
            </div>  
          </section> 
          <section className="login-section common-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  {renderForm()}
                </div>
              </div>
            </div>
          </section>
        </main>
    </React.Fragment>
  );
};

export default LoginPage;
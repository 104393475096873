/*
* @Author: abhi
* @Date:   2024-06-08 14:30:25
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 15:25:31
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

const parentHost = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

class EventServices {
	async getEvents(data){
		const url = (data.event_type === 'appointment') ? `${API_URL}get-appointment` : `${API_URL}get-classes`;;
		const headers = {
			'Content-Type': 'application/json'
		};
		data.site_url = parentHost;
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async getEventDetail(data){
		const url = (data.event_type === 'appointment') ? `${API_URL}get-appointment-details` : `${API_URL}get-class-details`;
		const headers = {
			'Content-Type': 'application/json'
		};
		data.site_url = parentHost;
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async checkBookingRequirement(data,auth_token){
		const url = `${API_URL}check-booking-requirement`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		data.site_url = parentHost;
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async bookeEvent(data,auth_token){
		const url = `${API_URL}book-event`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		data.site_url = parentHost;
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async checkBookedEvent(data,auth_token){
		const url = `${API_URL}check-booked-event`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		data.site_url = parentHost;
		let formData = data;
		return axios({
			url:url,
			method: 'POST',
			data: formData,
			headers: headers
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}
}

export default EventServices;
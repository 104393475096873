/*
* @Author: abhi
* @Date:   2024-06-08 12:45:57
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:30:55
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_URL, INDEX_URL, generateRandomString } from '../../settings/common';
import { Modal, Button } from 'react-bootstrap';
import { Validation } from '../../helpers/validation';
import ChildServices from '../../services/ChildServices';
import EventServices from '../../services/EventServices';
import MembershipPlanServices from '../../services/MembershipPlanServices';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';

const BookingModal = ({ requestData, showModal, onClose }) => {
	const [companyData, setCompanyData] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [authUser, setAuthUser] = useState({});
	const [children, setChildren] = useState({});
	const [membershipPlans, setMembershipPlans] = useState({});
	const [requiredMembershipPlans, setRequiredMembershipPlans] = useState({});
	const [isActive, setIsActive] = useState(false);
	const [isFree, setIsFree] = useState(false);
	const [isQuickPay, setIsQuickPay] = useState(false);
	const [isEventBooked, setIsEventBooked] = useState(true);
	const childServices = new ChildServices;
	const eventServices = new EventServices();
	const membershipPlanServices = new MembershipPlanServices;
	const navigate = useNavigate();
	
	useEffect(() => {
		let company_data = localStorage.getItem('company_data');
		if(typeof company_data !== 'undefined') {
  			setCompanyData(JSON.parse(company_data));
  			const auth_user = JSON.parse(localStorage.getItem('auth_user'));
	        if (auth_user) {
	            setAuthUser(auth_user);
	            getChildren(auth_user);
	        }
  		}
  	},[]);

  	const getChildren = (auth_user) => {
    	childServices.getChildren(auth_user.auth_token).then(response => {
    		if(response.data.status === 'success') {
    			setChildren(response.data.data);
    		}
    	});
    }

  	const handleChange = (e) => {
  		const { name, value } = e.target;
  		const newFormData = {
  			...formData,
  			[name]: value,
  		};
  		setFormData(newFormData);
  		if(name === 'child_id') {
  			if(value !== '') {
	  			var is_booked = isBooked(value);
	  			if(!is_booked) {
	  				checkBookingRequirement(value);
	  			}
	  		} else {
	  			setIsEventBooked(true);
	  			setIsActive(0);
	  			setIsFree(0);
	  			setMembershipPlans({});
	  			setRequiredMembershipPlans({});
	  		}
  		}
  	};

  	const checkBookingRequirement = (child_id) => {
  		if(child_id !== null) {
  			requestData.child_id = child_id;
  			requestData.source = 'web';
  			eventServices.checkBookingRequirement(requestData,authUser.auth_token).then(response => {
  				if(response.data.status === 'success') {
  					setIsActive(response.data.data.is_active);
  					setIsFree(response.data.data.is_free);
  					setMembershipPlans(response.data.data.membership_plan);
  					setRequiredMembershipPlans(response.data.data.required_membership_plan);
  					setIsQuickPay(response.data.data.is_quick_pay);
  				} else {
  					setIsActive(0);
  					setIsFree(0);
  					setMembershipPlans({});
  					setRequiredMembershipPlans({});
  				}
  			});
  		}
  	}

  	const handleSubmit = (e) => {
  		e.preventDefault();
  		const errors = (isFree || isQuickPay) ? Validation(formData,['child_id']) : Validation(formData,['child_id','membership_plan_id']);
  		if (Object.keys(errors).length > 0) {
  			setErrors(errors);
  			return;
  		}
  		if(Object.keys(membershipPlans).length > 0 && !isQuickPay) {
  			bookEvent();
  		} else if(Object.keys(requiredMembershipPlans).length > 0 && !isQuickPay) {
  			purchaseMembership();
  		} else if(isQuickPay) {
  			createOrder();
  		} else if(isFree) {
  			bookEvent();
  		}
	  	
	}

  	const isBooked = (child_id) => {
  		var data = {event_id:requestData.id,event_type:requestData.booking_type,date:requestData.date,start_time:requestData.start_time,end_time:requestData.end_time,child_id:child_id};
  		var is_booked = 0;
  		eventServices.checkBookedEvent(data,authUser.auth_token).then(response=>{
  			if(response.data.status === 'success'){
  				is_booked = response.data.data.is_booked;
  				if(is_booked !== 0) {
  					setIsEventBooked(true);
  					showErrorMessage('This event already booked for this child.');
  				} else {
  					setIsEventBooked(false);
  				}
  			} else {
  				setIsEventBooked(true);
  				showErrorMessage('This event already booked for this child.');
  			}
  			return is_booked;
  		});
  	}

  	const purchaseMembership = () => {
  		const plan = getMembershipPlanDetails();
        if (plan) {
            const key = generateRandomString();
            localStorage.setItem(key, JSON.stringify(plan));
            setCurrentUrl(`check-out/${key}`);
            window.location.href = `${INDEX_URL}check-out/${key}`;
        }
  	}

  	const createOrder = () => {
  		const key = generateRandomString();
  		var data = {appointment_id:requestData.id,event_type:requestData.booking_type,date:requestData.date,start_time:requestData.start_time,end_time:requestData.end_time,company_id:companyData.id,location_id:companyData.location.id,source:'web'};
  		eventServices.getEventDetail(data).then(response => {
  			if(response.data.status === 'success') {
  				response.data.data.data.booking_details = data;
  				response.data.data.data.booking_details.child_id = formData.child_id;
  				localStorage.setItem(key, JSON.stringify(response.data.data.data));
  				setCurrentUrl(`check-out/${key}`);
  				navigate(`/check-out/${key}`);
  			} else {
  				showErrorMessage('Event not found.')
  			}
  		});
  	}

  	const getMembershipPlanDetails = () => {
  		var data = {plan_id:formData.membership_plan_id,company_id:authUser.company_id,location_id:authUser.location_id};
  		membershipPlanServices.getMembershipPlanDetails(data).then(response=>{
  			if(response.data.status === 'success') {
  				const plan = response.data.data;
  				if(plan) {
  					var data = {event_id:requestData.id,event_type:requestData.booking_type,date:requestData.date,start_time:requestData.start_time,end_time:requestData.end_time,child_id:formData.child_id};
  					plan.category_id = plan.category.id;
  					plan.category = plan.category.name;
  					plan.booking_details = data;
  					const key = generateRandomString();
  					localStorage.setItem(key, JSON.stringify(plan));
  					setCurrentUrl(`check-out/${key}`);
  					navigate(`/check-out/${key}`);
  					//window.location.href = `${INDEX_URL}check-out/${key}`;
  				}
  			} else {
  				showErrorMessage('Something went wrong.')
  			}
  		});
  	}

  	const handleMembershipClick = (membership_plan_id) => {
  		const newFormData = { ...formData, membership_plan_id };
        setFormData(newFormData);
        setErrors({});
  	}

  	const bookEvent = () => {
  		setLoading(true);
  		setErrors({});
  		formData.event_id = requestData.id;
  		formData.date = requestData.date;
  		formData.start_time = requestData.start_time;
  		formData.end_time = requestData.end_time;
  		formData.event_type = requestData.booking_type;
  		formData.source = 'web';
  		eventServices.bookeEvent(formData,authUser.auth_token).then(response => {
  			setLoading(false);
            if (response.data.status === 'success') {
            	showSuccessMessage(`${requestData.booking_type} booked successfully.`);
                onClose(); // Close the modal on successful booking
            } else {
                showErrorMessage('Booking failed:', response.data.message);
            }
        });
  	}

  	return (
		<React.Fragment>
			<Modal show={showModal} onHide={onClose}>
				<Modal.Header closeButton>
					<Modal.Title>Book {requestData.booking_type}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{Object.keys(children).length > 0 ? (
						<React.Fragment>
							<form id="" method="post" className="" onSubmit={handleSubmit}>
								<div className="form-group mb-3">
									<select className="form-control" id="child_id" name="child_id" value={formData.child_id || ''} onChange={handleChange}>
										<option value="">Select Child</option>
										{Object.keys(children).length > 0 && (
									        Object.entries(children).map(([key, value]) => (
									          <option key={key} value={value.id}>
									            {value.first_name} {value.last_name}
									          </option>
									        ))
									    )}
									</select>
									{errors.child_id && <div className="text-danger">{errors.child_id}</div>}
								</div>

								{Object.keys(membershipPlans).length > 0 && (
									<React.Fragment>
										<div className="form-group mb-3 membership_plan_div">
											{Object.keys(membershipPlans).length > 0 && (
										        Object.entries(membershipPlans).map(([index, value]) => (
										          <div className={(formData.membership_plan_id == index) ? "card mb-3 membership_plan active" : "card mb-3 membership_plan"} key={index} onClick={()=>handleMembershipClick(index)}>
										          	<div className="card-body">
										          		<div className="row">
										          			<div className="col-md-9">
										          				<h5 className="m-0">{value.name}</h5>
										          				<span className="grayTxt">{(value.credits !== null) ? value.credits : '∞'} credits</span>
										          			</div>
										          			<div className="col-md-3">
										          			</div>
										          		</div>
										          	</div>
		 								          </div>
										        ))
										    )}
											{errors.membership_plan_id && <div className="text-danger">{errors.membership_plan_id}</div>}
										</div>
									</React.Fragment>
								)}

								{Object.keys(requiredMembershipPlans).length > 0 && (
									<React.Fragment>
										<div className="form-group mb-3 membership_plan_div">
											{Object.keys(requiredMembershipPlans).length > 0 && (
										        Object.entries(requiredMembershipPlans).map(([index, value]) => (
										          <div className={(formData.membership_plan_id == index) ? "card mb-3 membership_plan active" : "card mb-3 membership_plan"} key={index} onClick={()=>handleMembershipClick(index)}>
										          	<div className="card-body">
										          		<div className="row">
										          			<div className="col-md-9">
										          				<h5 className="m-0">{value.name}</h5>
										          				<span className="grayTxt">{value.credits} credits</span>
										          			</div>
										          			<div className="col-md-3">
										          			</div>
										          		</div>
										          	</div>
		 								          </div>
										        ))
										    )}
											{errors.membership_plan_id && <div className="text-danger">{errors.membership_plan_id}</div>}
										</div>
									</React.Fragment>
								)}
								
								{!isEventBooked && (
									<Button type="submit" variant="primary" disabled={loading} >
										{loading ? 'Processing ...' : 'Confirm Booking'}
									</Button>
								)}
							</form>
						</React.Fragment>
					)
					:
						<React.Fragment>
							<Link to={`${SITE_URL}children`}>
								<Button type="button" variant="primary">Add Child</Button>
							</Link>
						</React.Fragment>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);

}
export default BookingModal;
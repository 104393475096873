/*
* @Author: abhishek714
* @Date:   2024-04-12 10:20:21
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:19:18
*/
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SITE_TITLE, INDEX_URL } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import { Validation } from '../../helpers/validation';
import UserServices from '../../services/UserServices';
import { AppContext } from '../../components/elements/AppContext';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const LoginPage = () => {
	const [companyData, setCompanyData] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const { setSharedAuthUser } = useContext(AppContext);
	const navigate = useNavigate();

  	useEffect(() => {
  		seo({
  			title: SITE_TITLE + ' || Login',
  			metaDescription: '',
  		});
  		let company_data = localStorage.getItem('company_data');
  		if(typeof companyData !== 'undefined') {
  			setCompanyData(JSON.parse(company_data));
  		}
  	},[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
    	...formData,
    	[name]: value,
    };
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
  	e.preventDefault();
  	const errors = Validation(formData,['email','password']);
  	if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    submitForm();
  };

  const submitForm = async () => {
  	setLoading(true);
  	if(typeof companyData.id !== 'undefined' && typeof companyData.location.id !== 'undefined') {
	  	formData.company_id = companyData.id;
	  	formData.location_id = companyData.location.id;
	  	const userServices = new UserServices();
	  	userServices.login(formData).then(response => {
	  		let data = response.data;
	  		if(data.status === 'success') {
	  			localStorage.setItem('auth_user', JSON.stringify(data.data));
	  			setSharedAuthUser(data.data);
	  			setCurrentUrl('profile');
	  			showSuccessMessage('Login successfully.');
	  			//window.location.href = INDEX_URL+'profile';
	  			navigate(INDEX_URL+'profile');
	  		} else {
	  			showErrorMessage(`${data.message}`)
	  		}
	  		setLoading(false);
	  	});
	 }
  };

  const renderLoginForm = () => {
  	return (
  		<React.Fragment>
  			<form id="login-form" method="post" className="box-shadow-div" onSubmit={handleSubmit}>
  				{errors.apiError && <p className="text-danger">{errors.apiError}</p>}
  				<div className="row">
		            <div className="col-md-12">
		              <div className="input-group mb-3">
		              	<span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-envelope fa-fw"></i></span>
		                <input type="text" className="form-control" id="email" placeholder="Email" name="email" value={formData.email || ''} onChange={handleChange}/>
		                {errors.email && <div className="text-danger">{errors.email}</div>}
		              </div>
		              <div className="input-group mb-3">
						<span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-lock fa-fw"></i></span>
		                <input type="password" className="form-control" id="password" placeholder="Password" name="password" value={formData.password || ''} onChange={handleChange}/>
		                {errors.password && <div className="text-danger">{errors.password}</div>}
		              </div>
		            </div>
		            <div className="col-md-6">
						<div className="mb-3">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
								<label className="form-check-label" htmlFor="flexCheckDefault">
									Remember me
								</label>
							</div>
						</div>
					</div>
					<div className="col-md-6">	
						<div className="mb-3">
							<p className="text-right m-0"><Link to={INDEX_URL+'forgot-password'}>Forgot Password?</Link></p>
						</div>
					</div>
		            <div className="col-md-12 mt-4">  
		              <button type="submit" className="btn btn-primary" id="register-btn2" disabled={loading}>{loading ? 'Processing...' : 'Login'}
		              </button>
		              <p className="text-center grayTxt mt-1">Don't have an account? <Link to="/register" className="redTxt"><strong>Register Now</strong></Link></p>
		            </div>
		        </div>
  			</form>
	    </React.Fragment>
	);
  }

  return(
  	<React.Fragment>
  		{loading && (
        <div className="overlay">
          <div className="loading">
            <div className="spinner"></div>
          </div>
        </div>
      )}
  		<main role="main" className="main-section-area">
          <section className="header-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="default-heading text-center">
                    <h1 className="text-white m-0">Login</h1>
                  </div>
                </div>
              </div> 
            </div>  
          </section> 
          <section className="login-section common-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  {renderLoginForm()}
                </div>
              </div>
            </div>
          </section>
        </main>
    </React.Fragment>
  );
};

export default LoginPage;
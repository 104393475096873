/*
* @Author: abhishek714
* @Date:   2024-04-10 15:47:40
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-01 12:02:51
*/
import React from 'react';
import Routings from './settings/Routings';
import Header from './components/elements/Header';
import Footer from './components/elements/Footer';
import { AppProvider } from './components/elements/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <AppProvider>
        <Header/>
        <Routings/>
        <Footer/>
      </AppProvider>
    </React.Fragment>
  );
}

export default App;
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import { INDEX_URL, clearData } from '../../settings/common';
import { AppContext } from '../../components/elements/AppContext';
import { showSuccessMessage } from '../../helpers/flash-messages';

const UserSidebar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const { setSharedAuthUser, setSharedCartCount } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const active_menu = sessionStorage.getItem('active_menu');
    if (active_menu !== undefined && active_menu !== null) {
      setActiveMenu(active_menu);
    }
  }, []);

  const handleClickEvent = (link) => {
    if (link !== 'logout') {
      setCurrentUrl(link);
      sessionStorage.setItem('active_menu',link);
    }
  };

  const logout = () => {
    clearData('logout');
    setSharedAuthUser({});
    setSharedCartCount(0); // Set cart count to 0 on logout
    showSuccessMessage('Logout successfully.');
    sessionStorage.setItem("current_url", "classes");
    navigate(`${INDEX_URL}`);
  };

  return (
    <React.Fragment>
      <div className="sideBar">
        <ul>
          <li><Link className={activeMenu === 'profile' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}profile`} onClick={() => handleClickEvent('profile')}>Profile</Link></li>
          <li><Link className={activeMenu === 'children' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}children`} onClick={() => handleClickEvent('children')}>Children</Link></li>
          <li><Link className={activeMenu === 'bookings' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}bookings`} onClick={() => handleClickEvent('bookings')}>Bookings</Link></li>
          <li><Link className={activeMenu === 'memberships' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}memberships`} onClick={() => handleClickEvent('memberships')}>Memberships and packs</Link></li>
          <li><Link className={activeMenu === 'payments' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}payments`} onClick={() => handleClickEvent('payments')}>Payments</Link></li>
          <li><Link className={activeMenu === 'payment-methods' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}payment-methods`} onClick={() => handleClickEvent('payment-methods')}>Payment methods</Link></li>
          <li><Link className={activeMenu === 'change-password' ? "nav-link active" : "nav-link"} to={`${INDEX_URL}change-password`} onClick={() => handleClickEvent('change-password')}>Change Password</Link></li>
          <li><Link className="nav-link" to={`${INDEX_URL}`} onClick={() => logout()}>Logout</Link></li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default UserSidebar;
/*
* @Author: abhi
* @Date:   2024-06-22 13:56:04
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-11 15:24:26
*/
import React, { createContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [SharedCartCount, setSharedCartCount] = useState(null);
  const [SharedActiveMenu, setSharedActiveMenu] = useState(null);
  const [SharedAuthUser, setSharedAuthUser] = useState({});

  return (
    <AppContext.Provider value={{ SharedCartCount, setSharedCartCount, SharedAuthUser, setSharedAuthUser, SharedActiveMenu, setSharedActiveMenu }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
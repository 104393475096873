/*
* @Author: abhi
* @Date:   2024-06-25 13:57:43
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 15:58:34
*/
import { toast } from 'react-toastify';

export const showSuccessMessage = (message) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
  });
};

export const showErrorMessage = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
  });
};

export const showWarnMessage = (message) => {
  toast.warn(message, {
    position: 'top-right',
    autoClose: 3000,
  });
};
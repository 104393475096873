/*
* @Author: abhi
* @Date:   2024-04-26 17:42:19
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 18:06:04
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, generateRandomString, clearData } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import MembershipPlanServices from '../../services/MembershipPlanServices';
import LoginModal from '../../components/elements/LoginModal';
import { showErrorMessage } from '../../helpers/flash-messages';

const ListPage = () => {
    const [companyData, setCompanyData] = useState({});
    const [resultData, setResultData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [authUser, setAuthUser] = useState({});
    const [isLogin, setIsLogin] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [activePlanId, setActivePlanId] = useState('all');
    const formData = {};
    const membershipPlanServices = new MembershipPlanServices();
    const navigate = useNavigate();
    
    useEffect(() => {
        seo({
            title: SITE_TITLE + ' || Pricing',
            metaDescription: '',
        });
        setCurrentUrl('pricing');
        
        checkLogin();

        getMembership();

    }, []);

    const checkLogin = () => {
        const auth_user = JSON.parse(localStorage.getItem('auth_user'));
        if (auth_user) {
            setAuthUser(auth_user);
            setIsLogin(1);
            return true;
        } else {
            return false;
        }
    }

    const getMembership = () => {
        const company_data = JSON.parse(localStorage.getItem('company_data'));
        if (company_data) {
            setCompanyData(company_data);
            formData.company_id = company_data.id;
            formData.location_id = company_data.location.id;

            membershipPlanServices.getMembershipPlans(formData)
                .then(response => {
                    setLoading(false);
                    if (response.data.status === 'success') {
                        setResultData(response.data.data);
                    } else {
                        if(response.data.error === 'refresh_data') {
                            showErrorMessage(response.data.message);
                            clearData('invalid_company');
                            navigate(`${INDEX_URL}`);
                        }
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error("Error fetching membership plans:", error);
                });
        }
    };

    const handleBookNow = (membership_id) => {
        if (!isLogin) {
            if(checkLogin()) {
                setShowModal(false);
                checkOut(membership_id);
            } else {
                setShowModal(true);
            }
        } else {
            checkOut(membership_id);
        }
    };

    const checkOut = (membership_id) => {
        const plan = getMembershipPlanById(membership_id);
        if (plan) {
            const key = generateRandomString();
            localStorage.setItem(key, JSON.stringify(plan));
            setCurrentUrl(`check-out/${key}`);
            window.location.href = `${INDEX_URL}check-out/${key}`;
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getMembershipPlanById = (id) => {
        for (const item of resultData) {
            for (const plan of item.membership_plan) {
                if (plan.id == id) {
                    plan.category = item.name;
                    return plan;
                }
            }
        }
        return null;
    };

    const shortPlans = (membership_id = null) => {
        if (membership_id != null) {
            setActivePlanId(membership_id);
        }
    };

    const renderSidebar = () => {
        if (resultData.length === 0) {
        	return (
        		<React.Fragment>
        			<div className="col-md-4">
                <div className="sideBar">
                	<ul>
                		<li><Link to={'#'}>All Options</Link></li>
                		<li><Link to={'#'}></Link></li>
                	</ul>
                </div>
              </div>
        		</React.Fragment>
        	)
        }
        return (
            <div className="col-md-4">
                <div className="sideBar">
                    <ul>
                        {(resultData.length > 0) &&
                            <>
                                <li><Link to={'#'} className={activePlanId === 'all' ? "active" : ""} onClick={() => shortPlans('all')}>All Options</Link></li>
                                {resultData.map((value, index) => (
                                    <li key={index}><Link to={'#'} onClick={() => shortPlans(value.id)} className={activePlanId === value.id ? "active" : ""}>{value.name}</Link></li>
                                ))}
                            </>
                        }
                    </ul>
                </div>
            </div>
        );
    };

    const renderMembershipPlans = () => {
        if (loading) {
            return (
                <div className="row">
                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <div className="spinner"></div>
                    </div>
                </div>
            );
        }

        if (resultData.length === 0) {
		        return (
		            <div className="col-md-8">
		            	<form id="pricing-section">
		            		<div className="row">
		            			<div className="col-md-12">
		            				<h2 className="text-uppercase">Pricing</h2>
		                			<div className="row">
		                				<p className="text-center"><h6>No membership plans available.</h6></p>
		                			</div>
		                	</div>
		                </div>
		              </form>
		            </div>
		        );
		    }
        
        return (
            <div className="col-md-8">
                <form id="pricing-section">
                    <div className="row">
                        {resultData.map((value, index) => (
                            <div className={activePlanId === 'all' || activePlanId === value.id ? 'col-md-12 mt-4 all-options' : 'col-md-12 mt-4 all-options d-none'} key={index} id={`membership_plan_${value.id}`}>
                                <h2 className="text-uppercase">{value.name}</h2>
                                <div className="row">
                                    {value.membership_plan.length > 0 ?
                                        (value.membership_plan.map((plan, planIndex) => (
                                        <div className="col-md-4" key={planIndex}>
                                            <div className="pricingBox">
                                                <div className="pricingHeader">
                                                    <h6 className="m-0">{plan.name}</h6>
                                                    <h5>{`${companyData.location.currency_symbol}${plan.price}`}</h5>
                                                </div>
                                                <div className="pricingTxt">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li><Link to="#"><i className="fa-solid fa-tag fa-fw"></i> <span>{(plan.membership_type === 'recurring') ? 'Subscription' : 'Credit Pack'}</span></Link></li>
                                                                <li><Link to="#"><i className="fa-regular fa-clock fa-fw"></i> <span>{(plan.membership_type === 'recurring') && 'Renews in '} {plan.billing_interval_count} {(plan.billing_interval_count > 1) ? `${plan.billing_interval}s` : `${plan.billing_interval}`}</span></Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li><Link to="#"><i className="fa-regular fa-credit-card fa-fw"></i> <span>{(plan.credits === null) ? 'Unlimited' : `${plan.credits} Credits`}</span></Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricingFooter">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleBookNow(plan.id)}>PURCHASE</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h4>No plans available.</h4>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
            </div>
        );
    };

    return (
        <React.Fragment>
            <main role="main" className="main-section-area">
                <section className="header-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="default-heading text-center">
                                    <h1 className="text-white m-0">Pricing</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pricing-section common-padding">
                    <div className="container">
                        <div className="row">
                            {renderSidebar()}
                            {renderMembershipPlans()}
                        </div>
                    </div>
                </section>
            </main>
            {showModal && (
                <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered centered" role="document">
                        <div className="modal-content">
                            <LoginModal showModal={showModal} onClose={handleCloseModal} />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ListPage;
/*
* @Author: abhishek714
* @Date:   2024-04-23 12:41:26
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 18:13:33
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, clearData } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import ProductService from '../../services/ProductServices';
import { showErrorMessage } from '../../helpers/flash-messages';

const ListPage = () => {
	const { pageNumber } = useParams();
	const [companyData, setCompanyData] = useState({});
	const [productData, setProductData] = useState({});
  const [filteredProducts, setFilteredProducts] = useState({});
	const [featuredProductData, setFeaturedProductData] = useState({});
	const [resultData, setResultData] = useState({});
	const [loading, setLoading] = useState(true);
	const formData = {};
	const productService = new ProductService();
	const currentPage = parseInt(pageNumber, 10) || 1;
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
	
	useEffect(() => {
		setCurrentUrl('shop');
  		seo({
  			title: SITE_TITLE + ' || Shop',
  			metaDescription: '',
  		});
  		setTimeout(() => {
	  		getProducts();
	  	},10);
	  	
	},[]);

	const handleClickEvent = (link) => {
    setCurrentUrl(link);
  }

  const handleSearchInputChange = (event) => {
    const filteredProducts = productData.filter((product) =>
      product.name.toLowerCase().includes(event.target.value)
    );
    setFilteredProducts(filteredProducts);
    setSearchQuery(event.target.value);
  };

	const getProducts = (page=null) => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
			company_data = JSON.parse(company_data);
  			setCompanyData(company_data);
  			if(typeof company_data.id !== 'undefined' && typeof company_data.location.id !== 'undefined') {
			  	formData.company_id = company_data.id;
			  	formData.location_id = company_data.location.id;
			  	formData.page = (page===null) ? currentPage: page;
		  		productService.getProducts(formData).then(response => {
		  			setLoading(false);
			    	if(response.data.status === 'success') {
			    		setResultData(response.data.data);
			    		setProductData(response.data.data.products);
              setFilteredProducts(response.data.data.products);
			    		if (typeof response.data.data.products !== 'undefined' && Object.keys(response.data.data.products).length > 0) {
					        const featuredProducts = Object.entries(response.data.data.products)
					          .filter(([key, value]) => value.is_featured === '1')
					          .map(([key, value]) => value);

					        setFeaturedProductData(featuredProducts);
					      }
			    	} else {
		  				if(response.data.error === 'refresh_data') {
		  					showErrorMessage(response.data.message);
		  					clearData('invalid_company');
		  					navigate(`${INDEX_URL}`);
		  				}
		  			}
			    });
		  	}
  		}
	}

	const renderFeaturedProducts = () => {
		return(
			<React.Fragment>
				{(Object.keys(featuredProductData).length > 0) ? 
					<React.Fragment>
						<ul className="featuredList">
							{Object.entries(featuredProductData).map(([key, value]) => (
								<React.Fragment key={key}>
									<li key={key}>
										<Link to={`${SITE_URL}product/${value.slug}`} onClick={() => handleClickEvent(`product/${value.slug}`)}>
											<div className="imgDiv">
												<img src={value.image} alt="" />
											</div>
											<div className="featuredTxt">
												<h6>{value.name}</h6>
												<p>{companyData.location.currency_symbol+''+value.price}</p>
											</div>
										</Link>
									</li>
								</React.Fragment>
							))}
						</ul>
					</React.Fragment>
					:
					<React.Fragment>
					</React.Fragment>
				}
			</React.Fragment>
		)
	}
	
	const renderLeftside = () => {
		return(
			<React.Fragment>
				<div className="col-md-4">	
					<div className="row">
						<div className="col-md-12 mb-3">		
							<div className="searchIcon">
								<h3>Search</h3>
								<div className="input-group col-md-4">
									<input className="form-control py-2" type="search" placeholder="Search..." id="example-search-input" value={searchQuery}
                    onChange={handleSearchInputChange}/>
								</div>
							</div>	
						</div>
						<div className="col-md-12 mb-3">	
							<h3>Featured products</h3>
							{renderFeaturedProducts()}
						</div>
					</div>	
				</div>
			</React.Fragment>
		)
	}

	const renderResultText = () => {
		let start_count = (typeof resultData.current_page !== 'undefined' && Object.keys(resultData.products).length > 1) ? ((resultData.current_page === 1) ? 1 : (((resultData.current_page - 1) * resultData.limit) + 1)) : 0;
		let end_count = (typeof resultData.current_page !== 'undefined') ? ((resultData.current_page * resultData.limit) <= resultData.product_counts) ? (resultData.current_page * resultData.limit) : resultData.product_counts : 0;
		return(
			<React.Fragment>
				<div className="col-md-6">
					<p className="showingTxt">SHOWING {start_count}-{end_count}  OF {(typeof resultData.product_counts !== 'undefined') ? resultData.product_counts : 0} RESULTS</p>
				</div>
			</React.Fragment>
		)
	}

	const renderPagination = () => {
	    return (
	        <React.Fragment>
	            {(typeof resultData.total_page !== 'undefined' && resultData.total_page > 0) ?
	                <nav aria-label="Page navigation example" className="mt-4">
	                    <ul className="pagination justify-content-center">
	                        <li className={(resultData.prev_page === 0) ? 'page-item disabled' : 'page-item'}>
	                            <Link className="page-link" to={`${SITE_URL}shop/page/${resultData.prev_page}`} onClick={() => getProducts(resultData.prev_page)} tabIndex="-1" aria-disabled={resultData.prev_page === 0 ? true : false}>
	                                <i className="fa-solid fa-angle-left fa-fw"></i>
	                            </Link>
	                        </li>
	                        {/* Loop through page numbers */}
	                        {[...Array(resultData.total_page)].map((_, index) => (
	                            <li className={`page-item ${index + 1 === resultData.current_page ? 'active' : ''}`} key={index}>
	                                <Link className="page-link" to={`${SITE_URL}shop/page/${index + 1}`} onClick={() => getProducts(index + 1)}>{index + 1}</Link>
	                            </li>
	                        ))}
	                        <li className={(resultData.current_page === resultData.total_page) ? 'page-item disabled' : 'page-item'}>
	                            <Link className="page-link" to={`${SITE_URL}shop/page/${resultData.next_page}`} onClick={() => getProducts(resultData.next_page)} tabIndex="-1" aria-disabled={(resultData.current_page === resultData.total_page) ? true : false}>
	                                <i className="fa-solid fa-angle-right fa-fw"></i>
	                            </Link>
	                        </li>
	                    </ul>
	                </nav>
	                :
	                <React.Fragment>
	                </React.Fragment>
	            }
	        </React.Fragment>
	    );
	}


	const renderProducts = () => {
	    return (
	        <React.Fragment>
	            {loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
	            {!loading && Object.keys(filteredProducts).length > 0 ? (
	                <div className="row">
	                    {Object.entries(filteredProducts).map(([key, value]) => (
	                        <div className="col-md-4" key={key}>
	                            <div className="shopBox">
	                                <Link to={`${SITE_URL}product/${value.slug}`} onClick={() => handleClickEvent(`product/${value.slug}`)}>
	                                    <img src={value.image} alt="" className="img-fluid d-block mx-auto" style={{ width:'126px', height:'126px' }}/>
	                                </Link>
	                                <h6><Link to={`${SITE_URL}product/${value.slug}`} onClick={() => handleClickEvent(`product/${value.slug}`)}>{value.name}</Link></h6>
	                                <p className="priceTxt">{companyData.location.currency_symbol + '' + value.price}</p>
	                                <Link to={`${SITE_URL}product/${value.slug}`} onClick={() => handleClickEvent(`product/${value.slug}`)} className="add-to-cart">View</Link>
	                            </div>
	                        </div>
	                    ))}
	                    {renderPagination()}
	                </div>
	            ) : null}
	            {!loading && Object.keys(filteredProducts).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <p className="text-center">
	                            <h3>Products not available.</h3>
	                        </p>
	                    </div>
	                </div>
	            ) : null}
	        </React.Fragment>
	    );
	};
	
	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Shop</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="shop-section common-padding">
					<div className="container">
						<div className="row">
							{renderLeftside()}
							<div className="col-md-8">	
								<div className="row">
									{renderResultText()}
									<div className="col-md-6">
										
									</div>
								</div>
								{renderProducts()}
							</div>
						</div>	
					</div>
				</section>
			</main>
		</React.Fragment>
	)
}

export default ListPage;
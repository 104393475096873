/*
* @Author: abhishek714
* @Date:   2024-04-10 15:56:02
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:35:16
*/
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SITE_TITLE, SITE_URL, INDEX_URL, ucwords, VALID_IMAGE_TYPES } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import { Validation } from '../../helpers/validation';
import ChildServices from '../../services/ChildServices';
import UserSidebar from '../../components/elements/UserSidebar';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const AddChildPage = () => {
	const [companyData, setCompanyData] = useState({});
	const [authUser, setAuthUser] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const fileInputRef = useRef(null);
	const navigate = useNavigate();
	
	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
		}

		let companyDataFromStorage = localStorage.getItem('company_data');
	    if (companyDataFromStorage !== undefined && companyDataFromStorage !== null) {
	        const data = JSON.parse(companyDataFromStorage);
	        setCompanyData(data);
	    }
		seo({
	      title: SITE_TITLE + ' || Add Child',
	      metaDescription: '',
	    });
	    setCurrentUrl('add-child');
	    sessionStorage.setItem('active_menu','children');

	}, [authUser.auth_token]);

	
	const handleAddImageClick = (e) => {
		e.preventDefault();
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	}

	const handleChange = (e) => {
	    const { name, value, files } = e.target;
	    if (name === 'profile_image') {
	      handleProfileImageChange(name, files);
	    } else {
	      const newFormData = {
	        ...formData,
	        [name]: value,
	      };
	      setFormData(newFormData);
	    }
	};

	const handleProfileImageChange = (name, files) => {
		if (files && files[0]) {
			const file = files[0];
			if (!VALID_IMAGE_TYPES.includes(file.type)) {
				setErrors({profile_image:'Invalid image type. Please upload a JPEG, PNG, or GIF file.'});
				return;
			} else {
				setErrors({profile_image:null});
			}
			const reader = new FileReader();
			reader.onload = (e) => {
				setImagePreview(e.target.result);
			};
			reader.readAsDataURL(file);
			const newFormData = {
				...formData,
				[name]: file,
			};
			setFormData(newFormData);
		}
	};

	const handleSubmit = (e) => {
  		e.preventDefault();
  		const error = Validation(formData,['email','first_name','last_name','date_of_birth']);
	  	if (Object.keys(error).length > 0 || (typeof errors.profile_image !== 'undefined' && errors.profile_image !== null)) {
	  		if(typeof errors.profile_image !== 'undefined'  && errors.profile_image !== null) {
	  			error['profile_image'] = 'Invalid image type. Please upload a JPEG, PNG, or GIF file.';
	  		}
	  		setErrors(error);
	  		return;
	  	}
	    submitForm();
  	}

  	const submitForm = async () => {
  		setLoading(true);
  		setErrors({});
  		const data = new FormData();
  		data.append('company_id', companyData.id);
  		data.append('location_id', companyData.location.id);
	  	Object.keys(formData).forEach(key => {
	  		if (key === 'date_of_birth' && formData[key]) {
	  			data.append(key, moment(formData[key]).format('YYYY-MM-DD'));
	  		} else {
	  			data.append(key, formData[key]);
	  		}
	  	});

  		const childServices = new ChildServices();
  		childServices.addChild(authUser.auth_token, data).then(response => {
  			let data = response.data;
	  		if(data.status === 'success') {
	  			showSuccessMessage('Child added successfully.');
	  			navigate(`${INDEX_URL}children`);
	  		} else {
				if(response.data.error === 'authentication_error') {
					localStorage.removeItem("auth_user");
					sessionStorage.removeItem("current_url");
					window.location.href = INDEX_URL;
				}
			}
  			setLoading(false);
  		});
  	}

	const renderProfileImage = () => {
		return(
			<React.Fragment>
				<div className="profile-img">
					<img src={imagePreview || SITE_URL+'assets/images/no-profile.png' } alt="Preview" className="rounded-circle d-block mx-auto" style={{ width: '200px', height: '200px' }}/>
					<input ref={fileInputRef} type="file" accept="image/*" id="profile_image" name="profile_image" onChange={handleChange} style={{ display: 'none' }} />
					<label htmlFor="profile_image">
					<i className="fa-solid fa-plus fa-fw" onClick={handleAddImageClick} style={{ cursor : 'pointer' }}></i>
					</label>
					{errors.profile_image && <div className="text-danger">{errors.profile_image}</div>}
				</div>
			</React.Fragment>
		)
	}

	const renderProfile = () => {
		return(
			<React.Fragment>
				<form id="edit-profile-form" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
					<div className="row">
						<div className="col-md-12">
							<div className="row">
							<div className="col-md-4">
								{renderProfileImage()}
							</div>
							<div className="col-md-8">	
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="first-name">First Name <span className="text-danger">*</span> :</label>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" placeholder="First Name" id="first_name" name="first_name" value={formData.first_name || ''} onChange={handleChange}/>
											{errors.first_name && <div className="text-danger">{errors.first_name}</div>}
										</div>	
									</div>
								</div>	
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="last_name">Last Name <span className="text-danger">*</span> :</label>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" placeholder="Last Name" id="last_name" name="last_name" value={formData.last_name || ''} onChange={handleChange}/>
											{errors.last_name && <div className="text-danger">{errors.last_name}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="email">Email <span className="text-danger">*</span> :</label>
										</div>
										<div className="col-md-7">
											<input type="text" className="form-control" placeholder="Email" id="email" name="email" value={formData.email || ''} onChange={handleChange}/>
											{errors.email && <div className="text-danger">{errors.email}</div>}
										</div>	
									</div>
								</div>
								<div className="mb-3">
									<div className="row">
										<div className="col-md-5 align-self-center">
											<label htmlFor="date_of_birth">Date of Birth <span className="text-danger">*</span> :</label>
										</div>
										<div className="col-md-7">
											<DatePicker className="form-control datepicker" id="date_of_birth" name="date_of_birth" placeholderText="DD/MM/YYYY" selected={formData.date_of_birth ? new Date(formData.date_of_birth) : null} onChange={date => handleChange({ target: { name: 'date_of_birth', value: date } })} dateFormat="dd/MM/yyyy" autocomplete="off"/>	
											{errors.date_of_birth && <div className="text-danger">{errors.date_of_birth}</div>}
										</div>	
									</div>
								</div>
								<div className="col-md-12">
									<div className="mb-3">
										<div className="btn-right">
											<button type="submit" className="btn save-btn" disabled={loading}>Add</button>
										</div>
									</div>	
								</div>	
							</div>		
						</div>	
						</div>
					</div>
				</form>
			</React.Fragment>
		)
	}
	return (
		<React.Fragment>
			{loading && (
		        <div className="overlay">
		          <div className="loading">
		            <div className="spinner"></div>
		          </div>
		        </div>
		    )}
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">Add Child</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="profile-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								{renderProfile()}
							</div>
						</div>
					</div>
				</section>
			</main>  
		</React.Fragment>
	);
}

export default AddChildPage;
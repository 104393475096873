/*
* @Author: abhishek714
* @Date:   2024-04-19 11:41:12
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:17:10
*/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SITE_URL } from '../settings/common';

export const setCurrentUrl = (link) => {
	sessionStorage.setItem('current_url',link);
}

// Custom hook to get the current URL stored in sessionStorage
export const useGetRedirectUrl = () => {
  const getRedirectUrl = () => {
    const link = sessionStorage.getItem('current_url');
    return link ? link : null;
  };

  return getRedirectUrl;
};
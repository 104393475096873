/*
* @Author: abhi
* @Date:   2024-04-27 13:01:55
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-04 14:40:01
*/
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Validation } from '../../helpers/validation';
import UserServices from '../../services/UserServices';
import { AppContext } from '../../components/elements/AppContext';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const LoginModal = ({ showModal, onClose }) => {
	const [companyData, setCompanyData] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const { setSharedAuthUser } = useContext(AppContext);

	useEffect(() => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
  			setCompanyData(JSON.parse(company_data));
  		}
  	},[]);

  	const handleChange = (e) => {
  		const { name, value } = e.target;
  		const newFormData = {
  			...formData,
  			[name]: value,
  		};
  		setFormData(newFormData);
  	};

  	const handleSubmit = (e) => {
  		e.preventDefault();
  		const errors = Validation(formData,['email','password']);
  		if (Object.keys(errors).length > 0) {
  			setErrors(errors);
  			return;
  		}
  		submitForm();
  	};

  	const submitForm = async () => {
  		if(typeof companyData.id !== 'undefined' && typeof companyData.location.id !== 'undefined') {
	  		formData.company_id = companyData.id;
	  		formData.location_id = companyData.location.id;
	  		const userServices = new UserServices();
	  		userServices.login(formData).then(response => {
	  			let data = response.data;
		  		if(data.status === 'success') {
		  			localStorage.setItem('auth_user', JSON.stringify(data.data));
		  			setSharedAuthUser(data.data);
		  			showSuccessMessage('Login successfully.');
		  			onClose();
		  			//window.location.reload();
		  		} else {
		  			showErrorMessage(`${data.message}`);
		  		}
		  	});
	  	}
	};

	return (
		<React.Fragment>
			<Modal show={showModal} onHide={onClose}>
				<Modal.Header closeButton>
					<Modal.Title>Login</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="" method="post" className="" onSubmit={handleSubmit}>
						<div className="form-group mb-3">
							<input type="text" className="form-control" id="email" placeholder="Enter Email" name="email" value={formData.email || ''} onChange={handleChange} />
							{errors.email && <div className="text-danger">{errors.email}</div>}
						</div>
						<div className="form-group mb-3">
							<input type="password" className="form-control" id="password" placeholder="Enter Password" name="password" value={formData.password || ''} onChange={handleChange}/>
							{errors.password && <div className="text-danger">{errors.password}</div>}
						</div>
						<Button type="submit" variant="primary">
							Login
						</Button>
					</form>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};

export default LoginModal;
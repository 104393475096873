/*
* @Author: abhi
* @Date:   2024-06-08 12:19:39
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 18:30:03
*/
import axios from 'axios';
import {API_URL} from '../settings/common.js';

const parentHost = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

class ChildServices {
	async getChildren(auth_token, data){
		const url = `${API_URL}get-children`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		data.site_url = parentHost;
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async assignChild(auth_token,data){
		const url = `${API_URL}assign-child`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data,
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async addChild(auth_token,data){
		const url = `${API_URL}add-child`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data,
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

	async removeChild(auth_token,data){
		const url = `${API_URL}remove-child`;
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+auth_token+'',
		};
		return axios({
			url:url,
			method: 'POST',
			headers: headers,
			data: data,
		}).then(function(response){
			return response;
		}).catch(function(error){
			return error.response;
		});
	}

}

export default ChildServices;
/*
* @Author: abhi
* @Date:   2024-06-20 18:21:00
* @Last Modified by:   abhi
* @Last Modified time: 2024-07-20 11:31:06
*/
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ChildServices from '../../services/ChildServices';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const DeleteModal = ({ requestData, showModal, onClose, onDeleteSuccess }) => {
	const [companyData, setCompanyData] = useState({});
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [authUser, setAuthUser] = useState({});
	const childServices = new ChildServices;

	useEffect(() => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
  			setCompanyData(JSON.parse(company_data));
  			const auth_user = JSON.parse(localStorage.getItem('auth_user'));
	        if (auth_user) {
	            setAuthUser(auth_user);
	        }
  		}
  	},[]);

  	const handleSubmit = (e) => {
  		e.preventDefault();
  		formData.company_id = companyData.id;
  		formData.location_id = companyData.location.id;
  		formData.id = requestData.id;
  		childServices.removeChild(authUser.auth_token, formData).then(response => {
  			if(response.data.status == 'success') {
  				showSuccessMessage('Child deleted successfully.');
  				onClose();
  				onDeleteSuccess();
  			}
  		});
  	}

	return (
		<React.Fragment>
			<Modal show={showModal} onHide={onClose}>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id="" method="post" className="">
						<div className="form-group mb-3">
							<h3>{requestData.message}</h3>
						</div>

						
						<Button type="button" variant="primary" disabled={loading} onClick={handleSubmit}>
							{loading ? 'Processing ...' : 'Confirm'}
						</Button>
					</form>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);

}

export default DeleteModal;
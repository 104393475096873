/*
* @Author: abhishek714
* @Date:   2024-04-25 13:09:22
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 18:08:26
*/
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, ucwords, generateRandomString, renderHTML, clearData } from '../../settings/common';
import { AddToCart, CartData, cartItemCounts } from '../../helpers/cart';
import { seo } from '../../settings/seo';
import ProductService from '../../services/ProductServices';
import { AppContext } from '../../components/elements/AppContext';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const ProductDetail = () => {
	const { slug } = useParams();
	const [product, setProduct] = useState({});
	const [companyData, setCompanyData] = useState({});
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState({});
	const [cartData, setCartData] = useState({});
	const [pageTitle, setPageTitle] = useState(SITE_TITLE);
	const { setSharedCartCount } = useContext(AppContext);
	const productService = new ProductService();
	const navigate = useNavigate();

	useEffect(() => {
  		seo({
  			title: pageTitle,
  			metaDescription: '',
  		});
  		getProduct();
  	},[]);

	useEffect(() => {
	    // Set default product size if available
	    if (product.product_size && product.product_size.length > 0) {
	      const defaultSizeId = product.product_size[0].id;
	      setFormData(prevState => ({ ...prevState, product_size_id: defaultSizeId }));
	    }
	    setFormData(prevState => ({ ...prevState, quantity: 1 }));
	}, [product]);

	useEffect(() => {
		if(cartData !== null) {
			setFormData(cartData);
			setSharedCartCount(cartItemCounts);
		}
	},[cartData]);


	const changeQuantity = (action) => {
		let quantity = (typeof formData.quantity !== 'undefined' && formData.quantity !== '') ? formData.quantity : 1;
		if(action === 'plus' && quantity < product.remaining_quantity) {
			quantity = quantity+1;
		} else if(quantity > 0 && action === 'minus') {
			quantity = quantity-1;
		}
		const newFormData = {
	    	...formData,
	    	['quantity']: quantity,
	    };
	    setFormData(newFormData);
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
	    const newFormData = {
	    	...formData,
	    	[name]: value,
	    };
	    setFormData(newFormData);
	}

	const addToCart = () => {
		formData.product_id = product.id;
		formData.product_amount = product.price;
		formData.product_image = product.image;
		formData.product_name = product.name;
		formData.remaining_quantity = product.remaining_quantity;
		formData.quantity = (formData.quantity) ? formData.quantity : 1;
		AddToCart(formData);
		setCartData(CartData(product.id));
		setSharedCartCount(cartItemCounts);
		showSuccessMessage(`${product.name} added successfully to the cart.`);

	}

	const getProduct = () => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
			company_data = JSON.parse(company_data);
  			setCompanyData(company_data);
  			if(typeof company_data.id !== 'undefined' && typeof company_data.location.id !== 'undefined') {
			  	formData.company_id = company_data.id;
			  	formData.location_id = company_data.location.id;
			  	formData.search_by = 'slug';
			  	formData.search_by_value = slug;
			  	productService.getProductDetails(formData).then(response => {
			  		if(response.data.status === 'success') {
			  			setProduct(response.data.data);
			  			setPageTitle(`${SITE_TITLE} || ${response.data.data.name}`);
			  			setLoading(false);
			  			setCartData(CartData(response.data.data.id));
			  		} else {
		  				if(response.data.error === 'refresh_data') {
		  					showErrorMessage(response.data.message);
		  					clearData('invalid_company');
		  					navigate(`${INDEX_URL}`);
		  				}
		  			}
			  	});
			  	setFormData({});
			}
		}
	}

	const renderBreadCrumb = () => {
	  return (
	    <div className="container">
	      <div className="row">
	        <div className="col-md-12">
	          <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
	            <ol className="breadcrumb">
	              <li className="breadcrumb-item"><Link to={`${SITE_URL}`}>Home</Link></li>
	              <li className="breadcrumb-item"><Link to={`${INDEX_URL}shop`}>Product</Link></li>
	              {typeof product.name !== 'undefined' ? (
	                <li className="breadcrumb-item active" aria-current="page">{ucwords(product.name)}</li>
	              ) : (
	                ''
	              )}
	            </ol>
	          </nav>
	        </div>
	      </div>
	    </div>
	  );
	};

	const renderImageSlider = () => {
    return (
        <React.Fragment>
            <div className="col-md-6">
                <div className="product-carousel">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {(typeof product.product_image !== 'undefined' && Object.keys(product.product_image).length > 0) ?
                                Object.entries(product.product_image).map(([index, value]) => (
                                    <div className={`carousel-item ${index == 0 ? 'active' : ''}`} key={index}>
                                        <img src={value.image} className="d-block w-100 img-fluid mx-auto" alt="..." />
                                    </div>
                                ))
                                : null
                            }
                        </div>
                        <div className="carousel-indicators">
                            {(typeof product.product_image !== 'undefined' && Object.keys(product.product_image).length > 0) ?
                                Object.entries(product.product_image).map(([index, value]) => (
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={`thumbnail ${index == 0 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${parseInt(index) + 1}`} key={index}>
                                        <img src={value.image} className="d-block w-100" alt="..." />
                                    </button>
                                ))
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};



	const renderSizeOptions = () => {

	  return (
	    <React.Fragment>
	    {(typeof product.product_size !== 'undefined' && product.product_size.length > 0) ? (
	        <div className="input-group quantity">
		        <select className="form-control" value={formData.product_size_id || ''} name="product_size_id" onChange={handleChange}>
		          {Object.entries(product.product_size).map(([key, value]) => (
		            <React.Fragment key={key}>
		              <option value={value.id}>{value.size}</option>
		            </React.Fragment>
		          ))}
		        </select>
		    </div>
	      ) : (
	        <React.Fragment>
	          {/* Add any fallback content here if needed */}
	        </React.Fragment>
	      )}
	    </React.Fragment>
	  );
	}

	const renderProductData = () => {
		return(
			<React.Fragment>
				<div className="col-md-6">	
					<div className="product-detailDiv">
						<h2>{ucwords(product.name)}</h2>
						<p><strong>Brand:</strong> {(typeof product.brand !=='undefined' && product.brand !== null && product.brand.name !== '') ? product.brand.name : ''}</p>
						<p className="m-0"><strong>About the product</strong></p>
						<p>{(product.description !== '') ? renderHTML(product.description) : 'No description available' } </p>
						<h4 className="price redTxt"><strong>{companyData.location.currency_symbol+''+product.price}</strong></h4>						
							{(product.remaining_quantity >= 0) ?
								<React.Fragment>
									{renderSizeOptions()}
									<div className="input-group quantity">
									  <span className="input-group-btn">
									    <button
									      type="button"
									      className="quantity-left-minus btn btn-number"
									      data-type="minus"
									      data-field=""
									      onClick={() => changeQuantity('minus')}
									    >
									      <span><i className="fa-solid fa-minus fa-fw"></i></span>
									    </button>
									  </span>
									  <input
									    type="text"
									    id="quantity"
									    name="quantity"
									    className="input-number"
									    min="1"
									    max={product.remaining_quantity}
									    value={formData.quantity || 1}
									    onChange={handleChange}
									  />
									  <span className="input-group-btn">
									    <button
									      type="button"
									      className="quantity-right-plus btn btn-number"
									      data-type="plus"
									      data-field=""
									      onClick={() => changeQuantity('plus')}
									    >
									      <i className="fa-solid fa-plus fa-fw"></i>
									    </button>
									  </span>
									</div>
									<button className="btn btn-primary" id="add-to-cart-btn" onClick={() => addToCart()}>Add to Cart</button>
								</React.Fragment>
								:
								<React.Fragment>
									<button className="btn btn-primary" id="add-to-cart-btn" disabled>Out of Stock</button>
								</React.Fragment>
							}
					</div>
				</div>
			</React.Fragment>
		)
	}

	const renderTabs = () => {
		return(
			<React.Fragment>
				<div className="row">
					<div className="col-md-12">
						<div className="myTabDiv">
							<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
							  <li className="nav-item" role="presentation">
								<button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
							  </li>
							  <li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Additional information</button>
							  </li>
							  <li className="nav-item" role="presentation">
								<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Reviews (0)</button>
							  </li>
							</ul>
							<div className="tab-content" id="pills-tabContent">
								<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
									<p>Sed bibendum diam nisl, non viverra erat mattis id. Quisque convallis massa id ex convallis, at imperdiet dolor consectetur. Sed at leo iaculis, placerat sem in, ultrices est. Curabitur leo arcu, tincidunt in mattis in, efficitur faucibus erat. Sed ultrices at ligula in fringilla. Aliquam consectetur eget quam non vulputate. Aliquam erat volutpat.</p>
									<p>Donec ultrices nulla at dolor sodales, et posuere orci lobortis. Integer felis sapien, porttitor at leo at, venenatis finibus elit. Pellentesque ac semper ipsum, sit amet facilisis justo. Sed imperdiet nunc sed lacus fringilla, nec iaculis risus placerat. Morbi euismod suscipit sapien eget egestas. Curabitur sit amet rutrum arcu, a scelerisque ante. Morbi dictum iaculis congue. Fusce dapibus convallis nunc, eget pellentesque justo aliquam sodales.</p> 														
								</div>
								<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">Additional information</div>
								<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">Reviews (0)</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

	const renderProduct = () => {
		return(
			<React.Fragment>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg"Name>
					{renderBreadCrumb()}	
				</section>
				{(loading) ? 
					<React.Fragment>
						<div className="row">
							<div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
								<div className="spinner"></div>
							</div>
						</div>
					</React.Fragment>
					:
					<React.Fragment>
						<section className="product-detail-section common-padding">
					<div className="container">
						<div className="row">
							{renderImageSlider()}
							{renderProductData()}
						</div>
					</div>
				</section>
					</React.Fragment>
				}
				
				
			</main>
		</React.Fragment>
	)
}
export default ProductDetail;
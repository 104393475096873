/*
* @Author: abhishek714
* @Date:   2024-04-10 14:27:57
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-04-10 14:28:03
*/
export function seo(data = {}) {
  data.title = data.title || 'Default title';
  data.metaDescription = data.metaDescription || 'Default description';
  data.metaDescription = data.metaDescription || 'Default description';

  document.title = data.title;
  document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
}
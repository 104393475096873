/*
* @Author: abhi
* @Date:   2024-06-06 20:11:43
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 17:49:15
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, SITE_URL, INDEX_URL, ucwords, clearData } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import EventServices from '../../services/EventServices';
import CommonService from '../../services/CommonServices';
import moment from 'moment';
import LoginModal from '../../components/elements/LoginModal';
import BookingModal from '../../components/elements/BookingModal';
import { showErrorMessage } from '../../helpers/flash-messages';

const ClassDetailPage = () => {
	const { slug } = useParams();
	const formData = {};
	const [loading, setLoading] = useState(true);
	const [authUser, setAuthUser] = useState({});
    const [isLogin, setIsLogin] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false);
	const [companyData, setCompanyData] = useState({});
	const [resultData, setResultData] = useState({});
	const [requestData, setRequestData] = useState({id: null, start_time: null, end_time: null, date: null});
	const eventService = new EventServices();
	const navigate = useNavigate();
	useEffect(() => {
		setCurrentUrl(`class-detail/${slug}`);
  		seo({
  			title: SITE_TITLE + ' || Class Details',
  			metaDescription: '',
  		});
  		checkLogin();
  		extractDetails(atob(slug));
  	},[]);

  	useEffect(() => {
        getEventDetail();
    }, [requestData]);

    const checkLogin = () => {
    	const auth_user = JSON.parse(localStorage.getItem('auth_user'));
        if (auth_user) {
            setAuthUser(auth_user);
            setIsLogin(1);
            return true;
        } else {
        	return false;
        }
    }

  	const extractDetails = (string) => {
  		const parts = string.split('-');
  		if (parts.length === 6) {
  			const date = parts[0] +'-'+parts[1]+'-'+parts[2];
  			const id = parts[3];
  			const startTime = parts[4];
  			const endTime = parts[5]; // Join end_time parts
  			setRequestData({id: id, start_time: startTime, end_time: endTime, date: date});
  			return {date,id,startTime,endTime};
  		} else {
  			return null;
  		}
  	}

  	const handleBookEvent = () => {
        if (!isLogin) {
        	if(checkLogin()) {
        		setShowModal(false);
        		bookigModal();
        	} else {
        		setShowModal(true);
        	}
        } else {
            bookigModal();
        }
    };

    const bookigModal = () => {
    	setShowBookingModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setShowBookingModal(false);
    };

  	const getEventDetail = () => {
		let company_data = localStorage.getItem('company_data');
		if(typeof companyData !== 'undefined') {
			company_data = JSON.parse(company_data);
  			setCompanyData(company_data);
  			if(typeof company_data.id !== 'undefined' && typeof company_data.location.id !== 'undefined') {
  				formData.company_id = company_data.id;
			  	formData.location_id = company_data.location.id;
			  	formData.source = 'web';
			  	formData.date = requestData.date;
			  	formData.start_time = requestData.start_time;
			  	formData.end_time = requestData.end_time;
			  	formData.class_id = requestData.id;
			  	formData.event_type = 'class';
			  	if(formData.date !== null && formData.start_time !== null && formData.end_time !== null) {
				  	eventService.getEventDetail(formData).then(response => {
				  		setLoading(false);
			  			if(response.data.status === 'success') {
			  				setResultData(response.data.data);
			  			} else {
			  				if(response.data.error === 'refresh_data') {
			  					showErrorMessage(response.data.message);
			  					clearData('invalid_company');
			  					navigate(`${INDEX_URL}`);
			  				}
			  			}
			  		});
				}
			}
		}
	}

	const renderBreadCrumb = () => {
		return (
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to={`${SITE_URL}`}>Home</Link></li>
								<li className="breadcrumb-item"><Link to={`${INDEX_URL}classes`}>Classes</Link></li>
								{typeof resultData.data !== 'undefined' ? (
									<li className="breadcrumb-item active" aria-current="page">{ucwords(resultData.data.name)}</li>
									) : (
									''
								)}
							</ol>
						</nav>
					</div>
				</div>
			</div>
	  );
	};

	const renderEventData = () => {
		return (
			<React.Fragment>
				<div className="col-md-6">
					<div className="product-carousel">
						<img src={resultData.data.image} className="img-fluid d-block"/>
					</div>
				</div>
				<div className="col-md-6">	
					<div className="product-detailDiv">
						<h2>{ucwords(resultData.data.name)}</h2>
						<p className="m-0"><strong>{ucwords(resultData.day_name)}, {moment(requestData.date).format("DD MMM")} @ {moment(resultData.data.start_time, 'HH:mm').format('hh:mm A')}</strong></p>
						<p className="m-0"><strong>at {ucwords(resultData.data.room)}</strong></p>
						<p><strong>{ucwords(resultData.data.coach)}</strong></p>
						<p className="m-0"><strong>Class Details</strong></p>
						<p>{resultData.data.description}</p>
						<button className="btn btn-primary" id="add-to-cart-btn" onClick={() => handleBookEvent()}>{(resultData.data.payment_method === 'credits') ? `Book Class ${resultData.data.credits} credits` : `Book Class`}</button>
					</div>
				</div>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					{renderBreadCrumb()}
				</section>
				{loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
	            {!loading && resultData !== null ? (
	            		<React.Fragment>
	            			<section className="product-detail-section common-padding">
	            				<div className="container">
	            					<div className="row">
	            						{renderEventData()}
	            					</div>
	            				</div>
	            			</section>
	            		</React.Fragment>
	            ) : null}
	            {showModal && (
	                <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop="static">
	                    <div className="modal-dialog modal-dialog-centered centered" role="document">
	                        <div className="modal-content">
	                            <LoginModal showModal={showModal} onClose={handleCloseModal} />
	                        </div>
	                    </div>
	                </div>
	            )}
	            {showBookingModal && (
	                <div className="modal fade" id="bookingModal" tabIndex="-1" role="dialog" aria-labelledby="booingModalLabel" aria-hidden="true" data-backdrop="static">
	                    <div className="modal-dialog modal-dialog-centered centered" role="document">
	                        <div className="modal-content">
	                            <BookingModal requestData={{ ...requestData, booking_type: 'class' }} showModal={showBookingModal} onClose={handleCloseModal} />
	                        </div>
	                    </div>
	                </div>
	            )}
            </main>
            
        </React.Fragment>
	);

}

export default ClassDetailPage;
/*
* @Author: abhishek714
* @Date:   2024-04-10 15:56:02
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-07-12 16:17:46
*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SITE_TITLE, INDEX_URL } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import { Validation } from '../../helpers/validation';
import CommonServices from '../../services/CommonServices';
import UserServices from '../../services/UserServices';
import { showSuccessMessage, showErrorMessage } from '../../helpers/flash-messages';

const RegisterPage = () => {
	const [companyData, setCompanyData] = useState({});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [referal, setReferal] = useState({});
	const [showTextarea, setShowTextarea] = useState(false);
	const [loading, setLoading] = useState(false);

  useEffect(() => {
    seo({
      title: SITE_TITLE + ' || Sign up',
      metaDescription: '',
    });
    let company_data = localStorage.getItem('company_data');
    if(typeof company_data !== 'undefined') {
    	setCompanyData(JSON.parse(company_data));
    }
    
    const commonServices = new CommonServices();
    commonServices.getReferal().then(response => {
    	if(response.data.status === 'success') {
    		setReferal(response.data.data);
    	}
    });
  },[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
    	...formData,
    	[name]: value,
    };
    const shouldShowTextarea = value === 'Other' || (formData.refer === 'Other' && showTextarea);
    setFormData(newFormData);
    setShowTextarea(shouldShowTextarea);
  };

  const handleSubmit = (e) => {
  	e.preventDefault();
  	const errors = Validation(formData,['email','password','confirm_password','first_name','last_name','phone_number']);
  	if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    submitForm();
  };

  const submitForm = async () => {
  	setErrors({});
  	setLoading(true);
  	if(typeof companyData.id !== 'undefined' && typeof companyData.location.id !== 'undefined') {
	  	//formData.append({company_id:companyData.id,location_id:companyData.location.id})
	  	formData.company_id = companyData.id;
	  	formData.location_id = companyData.location.id;
	  	const userServices = new UserServices();
	  	userServices.register(formData).then(response => {
	  		let data = response.data;
	  		if(data.status === 'success') {
	  			localStorage.setItem('auth_user', JSON.stringify(data.data));
	  			setCurrentUrl('profile');
	  			showSuccessMessage('Registerd successfully.');
	  			window.location.href = INDEX_URL+'profile';
	  		} else {
	  			setErrors({apiError : data.message});
	  			showErrorMessage(`${data.message}`);
	  			//errors.apiError = data.message;
	  		}
	  		setLoading(false);
	  	});
	 }
  };

  const referSelectBox = () => {
  	return (
      <React.Fragment>
        <select className="form-control" id="refer" name="refer" value={formData.refer || ''} onChange={handleChange}>
          {Object.keys(referal).length > 0 ? (
            Object.keys(referal).map((value, key) => (
              <React.Fragment key={key}>
                <option value={value}>{value}</option>
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              <option value="">Select</option>
            </React.Fragment>
          )}
        </select>
      </React.Fragment>
    );
  }
  
  const renderRegisterForm = () => {
  	return (
  		<React.Fragment>
  			<form id="register-form" method="post" className="box-shadow-div" onSubmit={handleSubmit}>
  				{errors.apiError && <p className="text-danger">{errors.apiError}</p>}
  				<div className="row">
		            <div className="col-md-12">
		              <div className="mb-3">
		                <input type="text" className="form-control" id="email" placeholder="Email *" name="email" value={formData.email || ''} onChange={handleChange}/>
		                {errors.email && <div className="text-danger">{errors.email}</div>}
		              </div>
		              <div className="mb-3">
		                <input type="password" className="form-control" id="password" placeholder="Password *" name="password" value={formData.password || ''} onChange={handleChange}/>
		                {errors.password && <div className="text-danger">{errors.password}</div>}
		              </div>
		              <div className="mb-3">
		                <input type="password" className="form-control" id="confirm_password" placeholder="Confirm Password *" name="confirm_password" value={formData.confirm_password || ''} onChange={handleChange}/>
		                {errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
		              </div>
		              <div className="mb-3">
		                <input type="text" className="form-control" id="first_name" placeholder="First Name *" name="first_name" value={formData.first_name || ''} onChange={handleChange}/>
		                {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
		              </div>
		              <div className="mb-3">
		                <input type="text" className="form-control" id="last_name" placeholder="Last Name *" name="last_name" value={formData.last_name || ''} onChange={handleChange}/>
		                {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
		              </div>
		              <div className="mb-3">
		                <input type="text" className="form-control" id="phone_number" placeholder="Phone Number *" name="phone_number" value={formData.phone_number || ''} onChange={handleChange} />
		                {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
		              </div>
		              <div className="mb-3">
		                {referSelectBox()}
		                {errors.refer && <div className="text-danger">{errors.refer}</div>}
		              </div>
		              {showTextarea && (
		                <div className="mb-3">
		                  <textarea className="form-control" placeholder="How did you here about us?" rows="1" id="about-us" name="refer_other" value={formData.refer_other || ''} onChange={handleChange}></textarea>
		                </div>
		              )}
		            </div>
		            <div className="col-md-12 mt-4">  
		              <button type="submit" className="btn btn-primary" id="register-btn2" disabled={loading}>{loading ? 'Processing...' : 'Register'}
		              </button>
		              <p className="text-center grayTxt mt-1">Already have an account? <Link to="/login" className="redTxt"><strong>Login</strong></Link></p>
		            </div>
		        </div>
  			</form>
	    </React.Fragment>
	);
  }

  return(
  	<React.Fragment>
  		{loading && (
        <div className="overlay">
          <div className="loading">
            <div className="spinner"></div>
          </div>
        </div>
      )}
  		<main role="main" className="main-section-area">
          <section className="header-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="default-heading text-center">
                    <h1 className="text-white m-0">Register</h1>
                  </div>
                </div>
              </div> 
            </div>  
          </section> 
          <section className="register-section common-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  {renderRegisterForm()}
                </div>
              </div>
            </div>
          </section>
        </main>
    </React.Fragment>
  );
};

export default RegisterPage;
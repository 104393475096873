/*
* @Author: abhi
* @Date:   2024-06-11 20:05:54
* @Last Modified by:   abhi714
* @Last Modified time: 2024-08-09 18:49:14
*/
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SITE_TITLE, INDEX_URL, ucwords, clearData } from '../../settings/common';
import { seo } from '../../settings/seo';
import { setCurrentUrl } from '../../helpers/iframe-url-listner';
import UserServices from '../../services/UserServices';
import UserSidebar from '../../components/elements/UserSidebar';
import moment from 'moment';
import { showErrorMessage } from '../../helpers/flash-messages';

const BookingPage = () => {
	const [authUser, setAuthUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [bookingData, setBookingData] = useState({});
	const [upcomingBookingData, setUpcomingBookingData] = useState({});
	const [pastBookingData, setPastBookingData] = useState({});
	const userServices = new UserServices();
	const navigate = useNavigate();
	
	useEffect(() => {
		let auth_user = localStorage.getItem('auth_user');
		if(typeof auth_user !== 'undefined' && auth_user !== null) {
			setAuthUser(JSON.parse(auth_user));
			getBookingHistory(JSON.parse(auth_user));
		}
		seo({
	      title: SITE_TITLE+' || My Bookings',
	      metaDescription: '',
	    });
		setCurrentUrl(`bookings`);
		sessionStorage.setItem('active_menu','bookings');

	},[]);

	useEffect(() => {
		createData()
	},[bookingData]);

	const getBookingHistory = (auth) => {
		let data = {company_id:auth.company_id, location_id:auth.location_id};
		userServices.getBookingHistory(data,auth.auth_token).then(response => {
			setLoading(false);
			if(response.data.status === 'success') {
				setBookingData(response.data.data);
				//setUpcomingBookingData(response.data.data);
			} else {
				if(response.data.error === 'authentication_error') {
					localStorage.removeItem("auth_user");
					sessionStorage.removeItem("current_url");
					window.location.href = INDEX_URL;
				}
				if(response.data.error === 'refresh_data') {
                    showErrorMessage(response.data.message);
                    clearData('invalid_company');
                    navigate(`${INDEX_URL}`);
                }
			}
		});
	}

	const createData = () => {
		var pastBookings = [];
		let upcomingBookings = [];
		if(Object.keys(bookingData).length > 0) {
			Object.entries(bookingData).map(([key, value]) => {
				if(value.is_passed === 1) {
					pastBookings.push(value);
				} else {
					upcomingBookings.push(value);
				}

				upcomingBookings.sort((a, b) => {
				    const dateA = new Date(a.date);
				    const dateB = new Date(b.date);
				    return dateA - dateB;
				});

				pastBookings.sort((a, b) => {
				    const dateA = new Date(a.date);
				    const dateB = new Date(b.date);
				    return dateB - dateA;
				});
			})
		}
		setUpcomingBookingData(upcomingBookings);
		setPastBookingData(pastBookings);
	}
	
	const renderUpcomingBooking = () => {
		return (
			<React.Fragment>
				<div className="row">
            		<div className="col-md-9">
            			<h5>Upcoming</h5>
            		</div>
            	</div>
            	{loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
            	{!loading && Object.keys(upcomingBookingData).length > 0 ? (
	                <React.Fragment>
		                <div className="table-container">
			                <table className="table-scroll">
		                		<thead>
		                			<tr>
		                				<th>Event Date</th>
		                				<th>Event Time</th>
		                				<th style={{width:"35%"}}>Event</th>
		                				<th>Room</th>
		                			</tr>
		                		</thead>
		                		<tbody>
				                    {Object.entries(upcomingBookingData).map(([key, value]) => (
				                        <tr key={key}>
				                        	<td>{moment(value.date).format('ddd, DD MMM YY')}</td>
				                        	<td><b>{moment(value.start_time, "HH:mm").format("hh:mm A")}</b><br/>{value.duration} mins.</td>
				                        	<td style={{width:"10%"}}><img src={value.event.image} /><p className= "event_field"><b>{ucwords(value.event.name)}</b><br/>{ucwords(value.event_type)}</p></td>
				                        	<td>{ucwords(value.event.room.name)}</td>
				                        </tr>
				                    ))}
		                    	</tbody>
		                    </table>
		                </div>
	                </React.Fragment>
	            ) : null}
	            {!loading && Object.keys(upcomingBookingData).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <h3 className="text-center">No upcoming booking found.</h3>
	                    </div>
	                </div>
	            ) : null}
	        </React.Fragment>
		)
	}

	const renderPastBooking = () => {
		return (
			<React.Fragment>
            	<div className="row">
            		<div className="col-md-9">
            			<h5>Past</h5>
            		</div>
            	</div>
            	{loading && (
	                <div className="row">
	                    <div className="parentDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
	                        <div className="spinner"></div>
	                    </div>
	                </div>
	            )}
	            {!loading && Object.keys(pastBookingData).length > 0 ? (
	                <React.Fragment>
	                	<div className="table-container">
			                <table className="table-scroll">
			                	<thead>
		                			<tr>
		                				<th>Event Date</th>
		                				<th>Event Time</th>
		                				<th style={{width:"35%"}}>Event</th>
		                				<th>Room</th>
		                			</tr>
		                		</thead>
		                		<tbody>
				                    {Object.entries(pastBookingData).map(([key, value]) => (
				                        <tr key={key}>
				                        	<td>{moment(value.date).format('ddd, DD MMM YY')}</td>
				                        	<td><b>{moment(value.start_time, "HH:mm").format("hh:mm A")}</b><br/>{value.duration} mins.</td>
				                        	<td style={{width:"10%"}}><img src={value.event.image} /><p className= "event_field"><b>{ucwords(value.event.name)}</b><br/>{ucwords(value.event_type)}</p></td>
				                        	<td>{ucwords(value.event.room.name)}</td>
				                        </tr>
				                    ))}
		                    	</tbody>
		                    </table>
		                </div>
	                </React.Fragment>
	            ) : null}
	            {!loading && Object.keys(pastBookingData).length === 0 ? (
	                <div className="row">
	                    <div className="col-md-12">
	                        <h3 className="text-center">No past booking found.</h3>
	                    </div>
	                </div>
	            ) : null}
	        </React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<main role="main" className="main-section-area">
				<section className="header-bg">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="default-heading text-center">
									<h1 className="text-white m-0">My Bookings</h1>
								</div>
							</div>
						</div>
					</div>	
				</section>
				<section className="my-bookings-section common-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<UserSidebar/>
							</div>
							<div className="col-md-9">
								<div className="table-responsive mt-3" id="my-bookings-table">
					            	{renderUpcomingBooking()}
					            </div>
					            <hr/>
					            <div className="table-responsive mt-3" id="my-bookings-table">
					            	{renderPastBooking()}
					            </div>
							</div>
						</div>
					</div>
				</section>
			</main>  
		</React.Fragment>
	)

}

export default BookingPage
